import { useState } from "react";

interface UseReturn {
  date: string;
  handleChange: (value: string) => void;
  handleClickApply: () => void;
}

/**
 * ロジック: 予約モーダル
 */
export const useHooks = (
  handleClickReserve: (date: string) => void,
): UseReturn => {
  const [date, setDate] = useState<string>("");

  /**
   * 日付を変更した
   */
  const handleChange = (value: string): void => {
    setDate(value);
  };

  /**
   * 予約を押した
   */
  const handleClickApply = (): void => {
    handleClickReserve(date);
    setDate("");
  };

  return {
    date,
    handleChange,
    handleClickApply,
  };
};
