import { PersonalCategory } from "utilities/api/models";

/**
 * 親科目から現在の経費科目までのパスを経費科目の一覧で取得します。
 * このメソッドは再帰的に呼び出されます。
 * @param category 現在の経費科目
 * @param categories すべての経費科目の一覧
 * @param nestedCategories 計算中のパス(初期値は空配列)
 * @returns 親科目から現在の経費科目までのパスの経費科目一覧
 */
export function getParentCategoriesAndSelf(
  category: PersonalCategory,
  categories: PersonalCategory[],
  nestedCategories: PersonalCategory[] = [],
): PersonalCategory[] {
  const nextNestedCategories = nestedCategories.concat(category);
  const parentCategory = categories.find((c) => c.id === category.parentId);

  if (parentCategory) {
    return getParentCategoriesAndSelf(
      parentCategory,
      categories,
      nextNestedCategories,
    );
  }

  // 親から子供順で返す
  return nextNestedCategories.reverse();
}
