import { border } from "./border";
import { colors } from "./colors";
import { spacing } from "./spacing";
import { typography } from "./typography";

export const tokens = {
  colors: colors.color,
  typography: typography.font,
  spacing: spacing.spacing,
  border: border.border,
};
