import { ListForm } from "components/renewaled_ui/form_views";
import React from "react";
import { PersonalCategory } from "utilities/api/models";
import { CategorySelector } from "./CategorySelector";
import {
  OnSuggestionSelectedParams,
  OnSuggestionsUpdateRequestedUpdatedValue,
} from "./types";

interface Props {
  /**
   * ラベル
   */
  readonly label: string;
  /**
   * 必須入力かどうか
   */
  readonly isRequired: boolean;
  /**
   * 入力欄が編集可能かどうか
   */
  readonly editable: boolean;
  /**
   * 階層別の経費科目選択欄に入力中の文字列
   * 最上位の経費科目から順に格納される。
   */
  readonly nestedCategoryNames: string[];
  /**
   * 階層別の経費科目選択欄の選択候補
   * 最上位の経費科目から順に格納される。
   */
  readonly nestedCategories: {
    /**
     * 検索でフィルタされた経費科目の選択肢一覧
     */
    readonly current: PersonalCategory[];
  }[];
  /**
   * 1つの階層の経費科目選択欄で、文字列入力やクリアによって選択肢を更新する必要があった場合
   * @param updatedValue
   * @param index 階層のインデックス
   */
  readonly onSuggestionsUpdateRequestedRow: (
    updatedValue: OnSuggestionsUpdateRequestedUpdatedValue,
    index: number,
  ) => void;
  /**
   * 1つの階層の経費科目選択欄に文字を入力したとき
   * @param text 入力された文字
   * @param index 階層のインデックス(0が最上位の経費科目を示す)
   */
  readonly onTextChangedRow: (text: string, index: number) => void;
  /**
   * 1つの階層の経費科目で選択肢から選択したとき
   * @param e イベントオブジェクト
   * @param params
   * - suggestion 選択された経費科目
   * - suggestionValue 選択された経費科目の名前
   * - method 選択方法(click または enter)
   * @param index 階層のインデックス(0が最上位の経費科目を示す)
   */
  readonly onSuggestionSelectedRow: (
    e: React.FormEvent<HTMLInputElement>,
    params: OnSuggestionSelectedParams,
    index: number,
  ) => void;
  /**
   * 1つの階層の経費科目の選択欄をクリアしたとき
   * @param index 階層のインデックス(0が最上位の経費科目を示す)
   */
  readonly clearValueRow: (index: number) => void;
}

/**
 * 経費科目選択欄
 *
 * 経費科目の選択欄を描画します。
 * 経費科目には親子関係があり、親科目を選択すると子科目が表示されます。
 * 親科目から順に描画していきます。
 */
export const CategoryFormField: React.FC<Props> = ({
  label,
  isRequired,
  editable,
  nestedCategoryNames,
  nestedCategories,
  onSuggestionsUpdateRequestedRow,
  onTextChangedRow,
  onSuggestionSelectedRow,
  clearValueRow,
}) => {
  /**
   * jQuery validation plugin の検証クラス名を算出します。
   *
   * 検証クラス名は jQuery validation plugin を利用して、フォーム送信の直前に入力内容を検証するための定義です。
   * f必須入力の場合、検証クラス名は "validate[required, funcCallRequired[validateCategory]]" となります。
   * required は必須入力かどうか、funcCallRequired[validateCategory] は validateCategory メソッドで検証することを示します。
   * validateCategory メソッドは utilities/validation.js に定義されています。
   */
  const validationClass = isRequired
    ? "validate[required, funcCallRequired[validateCategory]]"
    : "";
  const inputPropsClassName = `form-control ${validationClass} category-input-field`;

  const disabled = !editable;

  return (
    <div>
      {nestedCategories.map(({ current }, index): JSX.Element => {
        const rowLabel = index === 0 ? label : "";
        const value = nestedCategoryNames[index] || "";
        const suggestions = current || [];

        const onSuggestionsUpdateRequested = (
          updatedValue: OnSuggestionsUpdateRequestedUpdatedValue,
        ): void => onSuggestionsUpdateRequestedRow(updatedValue, index);
        const onTextChanged = (text: string): void =>
          onTextChangedRow(text, index);
        const onSuggestionSelected = (
          e: React.FormEvent<HTMLInputElement>,
          params: OnSuggestionSelectedParams,
        ): void => onSuggestionSelectedRow(e, params, index);
        const clearValue = (): void => clearValueRow(index);

        return (
          <ListForm key={index} label={rowLabel} required={isRequired}>
            <CategorySelector
              inputPropsClassName={inputPropsClassName}
              value={value}
              suggestions={suggestions}
              onTextChanged={onTextChanged}
              onSuggestionsUpdateRequested={onSuggestionsUpdateRequested}
              onSuggestionSelected={onSuggestionSelected}
              clearValue={clearValue}
              disabled={disabled}
            />
          </ListForm>
        );
      })}
    </div>
  );
};
