import SimpleModal, { ButtonProps } from "components/SimpleModal";
import i18next from "i18n";
import React from "react";

interface Props {
  isVisible: boolean;
  title: string;
  deleteText: string;
  handleClickDelete: () => void;
  handleClose: () => void;
}

/**
 * 組織マスタ従業員一覧: 削除系の確認モーダル
 */
export const ReorganizationsChangeDeleteConfirmModal: React.FC<Props> = (
  props,
): JSX.Element => {
  const buttons: ButtonProps[] = [
    {
      color: "default",
      content: i18next.t("commons.actions.cancel"),
      onClick: props.handleClose,
    },
    {
      color: "danger",
      content: props.deleteText,
      onClick: props.handleClickDelete,
    },
  ];

  return (
    <SimpleModal
      show={props.isVisible}
      close={props.handleClose}
      title={props.title}
      buttons={buttons}
    >
      {props.children}
    </SimpleModal>
  );
};
