import { displayMessage } from "actions/ActionCreators";
import { fetchAsync } from "actions/AsyncAction";
import {
  postFormData,
  validateParams,
} from "applications/transactions/actions";
import i18next from "i18n";
import isNil from "lodash/isNil";
import { AnyAction, Dispatch } from "redux";
import Api from "utilities/api";
import { isValidDate } from "utilities/Assertions";
import { getMessageFromResponse, snakecaseKeys } from "utilities/Utils";

const prefix = "fareTransactions";

export function onRouteSelect(
  route,
  isRoundTrip,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): (dispatch: Dispatch<any>) => void {
  return (dispatch) => {
    dispatch(setRoute(route));
    dispatch(setAmount(route, isRoundTrip));
  };
}

// Submit
/*
 * 経費を作成、更新する
 * @param {bool} isNew - 新規作成/編集を区別するフラグ
 */
export function submitForm(
  isNew,
  callback,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): (dispatch: Dispatch<any>, getState: () => any) => AnyAction {
  return function submitFormThunk(dispatch, getState) {
    try {
      const { formData, searchBox } = getState();
      const param = collectParams(formData, searchBox);
      dispatch(validateParams(param));
      return dispatch(
        postFormData(isNew, createParameterJSON(param), callback),
      );
    } catch (error) {
      return dispatch(
        displayMessage("error", getMessageFromResponse(error as object)),
      );
    }
  };
}

// Fetch API
/*
 * 経路情報を取得する
 */
export function fetchRouteSummaries(
  updateRoute = true,
  // 他のモジュールから入力があるため、一時的に無効化している。
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateDate = false,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): (dispatch: Dispatch<any>, getState: () => any) => Promise<any> {
  return async (dispatch, getState) => {
    const { station, searchCondition, checked } = getState().searchBox;
    const { start, goal } = station;
    const { ownerId } = getState().formData;

    // 12:00:00を検索の基準点とする
    // 複数日付選択時は、最初に選択された日付で検索する

    const date = searchCondition.date
      ? new Date(searchCondition.date)
      : new Date();

    if (!isValidDate(date)) {
      // 2019/12/108などの不正値が入る場合がある
      return dispatch(
        displayMessage("error", i18next.t("transactions.errors.invalidDate")),
      );
    }

    date.setHours(12);
    date.setMinutes(0);
    date.setSeconds(0);

    const param = {
      origin: start && start.ekispertCode,
      destination: goal && goal.ekispertCode,
      userId: ownerId,
      ...searchCondition,
      date: date.toUTCString(),
    };

    const data = (await dispatch(
      fetchAsync(Api.transits.routeSummaries, param),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    )) as any;

    const routes = data.routeSummaries;

    dispatch(setRoutes(routes));

    if (!updateRoute) {
      // 経路更新が不要な時は、既に正しい経路情報を、経費が持っている状態
      // この時、現在の経路の更新をせず、かつ経路候補が見つからなかったとしても、エラーを表示しない
      return Promise.resolve();
    }
    // 経路が見つからなければ、経路をリセットする
    // 見つかった場合は、先頭の候補を経路としてセットする
    const firstRoute = routes[0] || null;
    await dispatch(setRoute(firstRoute));
    await dispatch(setAmount(firstRoute, checked.roundTrip));

    if (routes.length === 0) {
      dispatch(
        displayMessage("error", i18next.t("transactions.errors.routeNotFound")),
      );
    }
    return Promise.resolve();
  };
}

export function formatVia({ from, line, to }): string {
  if (!from || !line || !to) {
    return "";
  }
  return `${line} ${from} - ${to}`;
}

export const TOGGLE_EXPIRED_CACHE = `${prefix}/TOGGLE_EXPIRED_CACHE`;
export function expiredRouteSummaryCache(expiredCache): AnyAction {
  return {
    type: TOGGLE_EXPIRED_CACHE,
    value: expiredCache,
  };
}

// State Setter
export const SET_STATION_INPUT = `${prefix}/SET_STATION_INPUT`;

/*
 * 駅名の入力フォームに入力されている文字列を更新する
 * @param {string} key - 'start'/'goal'; 出発駅/到着駅を区別するフラグ
 * @param {string} value - セットされる文字列
 */
export function setStationInput(
  key,
  value,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): (dispatch: Dispatch<any>, getState: () => any) => void {
  return function setStationInputThunk(dispatch, getState) {
    const { station } = getState().searchBox;
    if (!isNil(station[key]) && station[key].name !== value) {
      // 駅名が更新されたら、選択されていた駅をリセットする
      dispatch(setStation(key, null));
    }
    dispatch({ type: SET_STATION_INPUT, key, value });
  };
}

/*
 * 訪問先の入力フォームの文字列を更新する
 */
export const SET_DESTINATION = `${prefix}/SET_DESTINATION`;
export function setDestination(value): AnyAction {
  return {
    type: SET_DESTINATION,
    value,
  };
}

/**
 * 選択されている駅を更新する
 * @param {string} key - 'start'/'goal'; 出発駅/到着駅を区別するフラグ
 * @param {object} value - 駅情報が入ったオブジェクト。補完候補としてサーバーから取得している
 */
export const SET_STATION = `${prefix}/SET_STATION`;
export function setStation(
  key,
  value,
  autoSearch = true,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): (dispatch: Dispatch<any>, getState: () => any) => any {
  return function setStationThunk(dispatch, getState) {
    dispatch({ type: SET_STATION, key, value });

    const { station } = getState().searchBox;
    if (autoSearch && station.start && station.goal) {
      return dispatch(fetchRouteSummaries());
    }
    return Promise.resolve();
  };
}

export const SET_RELAYS = `${prefix}/SET_RELAYS`;
/**
 * 経由駅のリストを更新する
 *
 * @param {object[]} relays
 */
export function setRelays(relays): AnyAction {
  return {
    type: SET_RELAYS,
    payload: { data: relays },
  };
}

export function resetRelays(): AnyAction {
  return setRelays([]);
}

export const UPDATE_RELAY = `${prefix}/UPDATE_RELAY`;
/**
 * @param {string} id - 更新対象の経由駅情報のID
 * @param {object} diff - 更新差分
 */
function updateRelay(id, diff): AnyAction {
  return {
    type: UPDATE_RELAY,
    payload: { id, diff },
  };
}

export function setRelayInput(id, text): AnyAction {
  return updateRelay(id, { text });
}

export function selectRelay(id, station): AnyAction {
  return updateRelay(id, { value: station ? { ...station } : null });
}

/*
 * SET_STATIONをstart, goal、合計2回呼び出すのと同じ
 * 2回呼び出すと、検索が2回実行されるなどの不都合があるため、別個の関数を用意する
 * @param {object} start - 出発駅のオブジェクト
 * @param {object} goal - 到着駅のオブジェクト
 */
export const SET_BOTH_STATIONS = `${prefix}/SET_BOTH_STATIONS`;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export function setBothStations(start, goal): (dispatch: Dispatch<any>) => any {
  return function setBothStationsThunk(dispatch) {
    dispatch({ type: SET_BOTH_STATIONS, start, goal });

    if (start && goal) {
      return dispatch(fetchRouteSummaries());
    }
    return Promise.resolve();
  };
}

/*
 * 経路を選択する
 */
export const SET_ROUTE = `${prefix}/SET_ROUTE`;
export function setRoute(route): AnyAction {
  return {
    type: SET_ROUTE,
    value: route,
  };
}

/*
 * 経路の候補をセットする
 */
export const SET_ROUTES = `${prefix}/SET_ROUTES`;
export function setRoutes(routes): AnyAction {
  return {
    type: SET_ROUTES,
    value: routes,
  };
}

/*
 * カテゴリのデフォルト値を設定する
 */
export const SET_DEFAULT_CATEGORY = `${prefix}/SET_DEFAULT_CATEGORY`;
export function setDefaultCategory(category): AnyAction {
  return {
    type: SET_DEFAULT_CATEGORY,
    value: category,
  };
}

/*
 * IC乗車券のデフォルト値を設定する
 */
export const SET_DEFAULT_IC = `${prefix}/SET_DEFAULT_IC`;
export function setDefaultIC(ic): AnyAction {
  return {
    type: SET_DEFAULT_IC,
    value: ic,
  };
}

/*
 * 検索オプションを更新する (on -> off, off -> on)
 * FareOptionsのpropとして使用される
 * @param {string} key - train/bus/plane
 */
export const SET_SEARCH_OPTIONS = `${prefix}/SET_SEARCH_OPTIONS`;
export function setSearchOptions(
  key,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): (dispatch: Dispatch<any>, getState: () => any) => void {
  return (dispatch, getState) => {
    dispatch({ type: SET_SEARCH_OPTIONS, key });
    if (key === "roundTrip") {
      const {
        searchBox: {
          route,
          checked: { roundTrip },
        },
      } = getState();
      dispatch(setAmount(route, roundTrip));
    }
  };
}

export const SET_SEARCH_CONDITION = `${prefix}/SET_SEARCH_CONDITION`;
export function setSearchCondition(
  key,
  value,
  fetchSummaries = fetchRouteSummaries,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): (dispatch: Dispatch<any>, getState: () => any) => any {
  return (dispatch, getState) => {
    const {
      searchCondition: { date },
      station: { start, goal },
    } = getState().searchBox;

    if (start && goal) {
      dispatch({ type: SET_SEARCH_CONDITION, key, value });

      // searchCondition.dateがnullの場合(=利用日入力フォームが初期化された時)は
      // 経路検索だけかけてトップに表示される経路は更新しない
      // (既存の交通費経費の詳細モーダルを開いた時に経路表示が変わってしまう問題への対応)
      const updateRoute = key !== "date" || !isNil(date);
      const updateDate = key === "date";
      return dispatch(fetchSummaries(updateRoute, updateDate));
    }

    return dispatch({ type: SET_SEARCH_CONDITION, key, value });
  };
}

export const SET_SEARCH_CONDITIONS = `${prefix}/SET_SEARCH_CONDITIONS`;
export function setSearchConditions(condition): AnyAction {
  return {
    type: SET_SEARCH_CONDITIONS,
    condition,
  };
}

/*
 * 出発駅と到着駅を入れ替える
 */
export const EXCHANGE_STATIONS = `${prefix}/EXCHANGE_STATIONS`;
export function exchangeStations(): (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getState: () => any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => any {
  return function exchangeStationsThunk(dispatch, getState) {
    const { station } = getState().searchBox;
    return dispatch(setBothStations(station.goal, station.start));
  };
}

// Suggestions
/*
 * 駅名の補完候補を選択する
 * @param {string} key - 'start'/'goal'; 出発駅/到着駅を区別するフラグ
 * @param {object} station - 選択した駅情報が入ったオブジェクト
 */
export const SELECT_STATION_SUGGESTION = `${prefix}/SELECT_STATION_SUGGESTION`;
export function selectStationSuggestion(
  key,
  station,
  autoSearch = true,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): (dispatch: Dispatch<any>) => any {
  return function selectStationSuggestionThunk(dispatch) {
    // setStationInputは明示的に呼ばなくても、AutoSuggestWrapperがonTextChangedを通じてcallする
    return dispatch(setStation(key, station, autoSearch));
  };
}

/*
 * 訪問先を補完候補から選択する
 */
export const SELECT_DESTINATION_SUGGESTION = `${prefix}/SELECT_DESTINATION_SUGGESTION`;
export function selectDestinationSuggestion(
  destination,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): (dispatch: Dispatch<any>, getState: () => any) => any {
  return function selectDestinationSuggestionThunk(dispatch, getState) {
    const { inputBy } = getState().formData;

    // 交通系IC連携 or モバイルSuica連携で作成された経費の場合に、経路情報の上書きをブロックする
    if (inputBy === "ic" || inputBy === "aggregation") {
      return;
    }

    // setDestinationは明示的に呼ばなくても、AutoSuggestWrapperがonTextChangedを通じてcallする
    dispatch(setBothStations(destination.station1, destination.station2));
  };
}

export const RESET_SEARCH_BOX = `${prefix}/RESET_SEARCH_BOX`;
export function resetSearchBox(): AnyAction {
  return {
    type: RESET_SEARCH_BOX,
  };
}

export const SET_AMOUNT = `${prefix}/SET_AMOUNT`;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setAmount(route, roundTrip): (dispatch: Dispatch) => any {
  return (dispatch) => {
    const amount = calcAmount(route, roundTrip);
    dispatch({
      type: SET_AMOUNT,
      payload: {
        amount,
      },
    });
  };
}

// Utilities
/*
 * サーバに送信するパラメータを構成する
 * 実際の通信時には、戻り値をcreateParameterJSONに渡す必要がある
 */
function collectParams(formData, searchBox): Record<string, unknown> {
  const { route, checked } = searchBox;
  let amount = (route && route.onewayAmount) || 0;
  if (checked.roundTrip) {
    amount *= 2;
  }

  const transaction = {
    id: formData.id,
    transactedAt: formData.transactedAt,
    shopName: searchBox.inputs.destination,
    route: {
      id: route.id || formData.routeId,
      isRoundTrip: checked.roundTrip,
    },
    amount,
    project: snakecaseKeys(formData.project) || {},
    isCorporate: formData.isCorporate,
    comment: formData.comment,
    taxCategoryName: formData.taxCategoryName,
    categoryName: formData.categoryName,
    creditCategoryName: formData.creditCategoryName,
    reportTitle: formData.reportTitle,
    preReportTitle: formData.preReportTitle,
    costAllocations: formData.costAllocations.filter(
      (x) => x !== null && x.numerator !== 0 && x.payerId,
    ),
  };
  return snakecaseKeys(transaction);
}

function createParameterJSON(param): { transactions: unknown[] } {
  return {
    transactions: [param],
  };
}

/**
 * 運賃を計算する
 * @param route 選択中の経路
 * @param isRoundTrip 往復なら true
 */
export function calcAmount(route, isRoundTrip): number | null {
  let amount = 0;
  if (route && !isNil(route.onewayAmount)) {
    amount = route.onewayAmount;
    if (isRoundTrip) {
      amount *= 2;
    }
  } else {
    return null;
  }
  return amount;
}
