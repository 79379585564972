import { ErrorResponse } from "hooks/useFetcher";
import flash from "utilities/flash";
import { getMessageFromResponse } from "utilities/Utils";

/**
 * APIリクエストのエラーがあったらflashメッセージを表示する
 * @param e エラーレスポンス
 */
export const handleRequestError = (e: ErrorResponse): void => {
  flash.error(getMessageFromResponse(e));
};
