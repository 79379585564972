import { displayMessage, redirectTo } from "actions/ActionCreators";
import { fetchAsync, fetchAsyncAll } from "actions/AsyncAction";
import {
  ExpenseCreateResponse,
  ExpenseUpdateResponse,
} from "apis/IExpenseDriver";
import { getExpenseApi } from "api_builders/ExpenseApi";
import Decimal from "decimal.js";
import i18next from "i18n";
import ceil from "lodash/ceil";
import escapeRegExp from "lodash/escapeRegExp";
import floor from "lodash/floor";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isFinite from "lodash/isFinite";
import isNaN from "lodash/isNaN";
import isNil from "lodash/isNil";
import omit from "lodash/omit";
import round from "lodash/round";
import toNumber from "lodash/toNumber";
import {
  addDependencies,
  create,
  equalDependencies,
  fractionDependencies,
  isIntegerDependencies,
  isPositiveDependencies,
  multiplyDependencies,
} from "mathjs";
import moment from "moment";
import { AnyAction } from "redux";
import Api from "utilities/api";
import { PersonalCategory, Project, SuperCategory } from "utilities/api/models";
import { DepartmentWithAbsolutePath } from "utilities/api/models/DepartmentWithAbsolutePath";
import { Department } from "utilities/api/responses/departments/IndexResponse";
import ApiManagements from "utilities/api_managements";
import {
  buildCategoriesByParentId,
  CategoriesByParentId,
  getNestedSuggestions,
  getParentCategoriesAndSelf,
} from "utilities/expenses/categories";
import S3Uploader from "utilities/s3_uploader";
import {
  getMessageFromResponse,
  initIntlCurrencyObj,
  notifyUnexpectedErrorToBugsnag,
  snakecaseKeys,
} from "utilities/Utils";
import { fetchOperatorEntry as fetchOperatorEntryApi } from "../components/EntryForms/AmountPerTaxCategoryCollectionFormField/fetch";
import {
  collectFieldParams,
  toExpenseEntryFormRequest,
} from "../ExpenseFormTransformer";
import { canRead } from "../utilities/transactionFormPolicy";
import * as memoBuilder from "../utilities/transactionMemoBuilder";

const math = create({
  addDependencies,
  equalDependencies,
  fractionDependencies,
  isIntegerDependencies,
  isPositiveDependencies,
  multiplyDependencies,
});
const prefix = `transactions`;

interface Expense {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly [key: string]: any;
}

interface AssignableReport {
  readonly id: string;
  readonly type: string;
  readonly title: string;
}

// Submit
/*
 * サーバにデータを送信する
 * @param {bool} isNew - 新規作成/編集を区別するフラグ
 * @param {object} param - 送信パラメータ
 * @param {function} callback - (expenses, error, message) => void. 領収書アップロード失敗時など、expensesとerrorの両方に値が入ることもある
 */
export function postFormData(
  isNew,
  param,
  callback,
  lockFormBtn = false,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  return async function postFormDataThunk(dispatch, getState): Promise<void> {
    const { formData } = getState();
    const paramData = param;

    if (!isNil(formData.category) && !formData.category.requiresCompanion) {
      paramData.entryForms[0].form_fields.filter((item) => {
        const itemData = item;
        if (itemData.type === "companion_input") itemData.formValue = [];
        return itemData;
      });
    }

    if (
      !isNil(formData.category) &&
      !formData.category.requiresOriginAndDestination
    ) {
      paramData.entryForms[0].form_fields.filter((item) => {
        const itemData = item;
        if (itemData.type === "origin_and_destination_by_category_input")
          itemData.formValue = {};
        return itemData;
      });
    }

    if (!isNil(formData.category) && !formData.category.requiresVisit) {
      paramData.entryForms[0].form_fields.filter((item) => {
        const itemData = item;
        if (itemData.type === "visit_by_category_input")
          itemData.formValue = "";
        return itemData;
      });
    }

    if (!isNil(formData.category) && !formData.category.requiresPurpose) {
      paramData.entryForms[0].form_fields.filter((item) => {
        const itemData = item;
        if (itemData.type === "purpose_by_category_input")
          itemData.formValue = "";
        return itemData;
      });
    }

    // NOTE: 経費科目の「登録番号欄入力」が「しない」設定の場合、登録番号、適格請求書発行事業者情報をクリアして送信する。
    if (
      !isNil(formData.category) &&
      !formData.category.requiresRegistratedNumber
    ) {
      paramData.entryForms[0].form_fields.forEach((item) => {
        const itemData = item;
        if (itemData.type === "eligible_invoice_confirmation_input") {
          // eslint-disable-next-line camelcase
          itemData.form_value.registrated_number = "";
          // eslint-disable-next-line camelcase
          itemData.form_value.invoicing_organization = {};
        }
      });
    }

    // 二重送信抑止
    dispatch(lockFormButton());

    let data: ExpenseUpdateResponse | ExpenseCreateResponse | null = null;

    try {
      const api = getExpenseApi();
      data = await (isNew
        ? api.createAll(paramData)
        : api.updateAll(paramData));

      const {
        localReceiptFile: { foreside, backside },
      } = formData;

      // 画像が更新されている場合、別途送信する
      if (foreside?.file || backside?.file) {
        const iterable: unknown[] = [];

        (data?.transactions || []).forEach((transaction) => {
          if (foreside?.file) {
            iterable.push(
              uploadImage(
                {
                  id: transaction.id,
                  isBackside: false,
                  rotation: foreside.rotation,
                },
                foreside.file,
              ),
            );
          }

          if (backside?.file) {
            iterable.push(
              uploadImage(
                {
                  id: transaction.id,
                  isBackside: true,
                  rotation: backside.rotation,
                },
                backside.file,
              ),
            );
          }
        });

        await Promise.all(iterable);
      }

      if (typeof callback === "function") {
        callback(data.transactions);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (get(error, "name") === "S3UploadError") {
        // 経費作成・更新が成功していればonSuccessを実行して、モーダルを閉じるなど必要なコールバックを処理する。
        // 画像送信に失敗している場合は追加でメッセージを表示する。
        if (typeof callback === "function") {
          callback(data?.transactions, error);
        }

        dispatch(displayMessage("error", error.message));
      } else {
        // 経費の登録・更新で失敗している場合
        const message =
          get(error, "responseJSON.message") ||
          i18next.t(
            `transactions.errors.${
              isNew ? "failedToCreateData" : "failedToUpdateData"
            }`,
          );
        if (typeof callback === "function") {
          callback(void 0, error, message);
        } else {
          dispatch(displayMessage("error", message));
        }

        // 通常のレスポンスエラーと異なる場合、errorの内容をBugsnagに通知する
        notifyUnexpectedErrorToBugsnag(error);
      }
    } finally {
      if (!lockFormBtn) {
        dispatch(unlockFormButton());
      }

      dispatch(closeConfirmationModal());
    }
  };
}

/*
 * @param {object} receiptParams
 * @param {File} file - アップロード対象の画像のFileオブジェクト
 * @returns {object|Array} - Ajaxのレスポンス。失敗した時はnullを返す
 */
export function uploadImage(receiptParams, file): Promise<unknown> {
  /* eslint-disable camelcase */
  const params = {
    transaction_id: receiptParams.id,
    original_filename: file.name,
    is_backside: receiptParams.isBackside,
    rotation: receiptParams.rotation,
  };
  /* eslint-enable camelcase */
  const retryNumber = 2; // 暫定で2回リトライしてみる

  return Promise.resolve(
    new S3Uploader().uploadTransactionReceiptImage(file, params, retryNumber),
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function validateCostAllocations(): any {
  return (dispatch, getState): void => {
    const costAllocations =
      getState().formData.formValues.cost_allocation_input || [];
    const validCostAllocations = costAllocations.filter((x) => x.payerId); // 部署の入力がある項目のみ

    if (validCostAllocations.length > 0) {
      const hasFractionalPart = validCostAllocations.some(
        (x) => !math.isInteger(x.numerator),
      );
      const hasNonPositiveValue = validCostAllocations.some(
        (x) => !math.isPositive(x.numerator),
      );
      const hasPayerDuplicature = validCostAllocations
        .map((x) => x.payerId)
        .some((x, index, array) => array.lastIndexOf(x) !== index);

      if (hasFractionalPart) {
        throw new Error(i18next.t("transactions.errors.costAllocationDecimal"));
      }
      if (hasNonPositiveValue) {
        throw new Error(
          i18next.t("transactions.errors.costAllocationNonPositive"),
        );
      }
      if (hasPayerDuplicature) {
        throw new Error(
          i18next.t("transactions.errors.costAllocationDuplicature"),
        );
      }

      const fractionalizer = (x): unknown =>
        math.fraction(x.numerator, x.denominator);
      const total = validCostAllocations
        .map(fractionalizer)
        .reduce((sum, x) => math.add(sum, x), 0);

      if (!math.equal(total, math.fraction(1))) {
        throw new Error(
          i18next.t("transactions.errors.costAllocationSumOfFraction"),
        );
      }
    }
  };
}

// 一括編集時の通貨・レート更新時のバリデーション
function validateCurrencyAndRateParams(currencyAndRate): void {
  if (currencyAndRate.rate_reference_type === "manual") {
    const exchangeRate = currencyAndRate.exchange_rate;
    if (isNil(exchangeRate) || exchangeRate === "") {
      throw new Error(i18next.t("transactions.errors.didNotEnterCurrencyRate"));
    }
  }
}

function validateAmountPerTaxCategoriesParams(
  amount,
  amountPerTaxCategories: { amount: number }[] = [],
): void {
  if (amountPerTaxCategories.length > 0) {
    const totalAmountPerTaxCategories = amountPerTaxCategories.reduce(
      (acc, a) => acc + a.amount,
      0,
    );
    if (amount !== totalAmountPerTaxCategories) {
      throw new Error(
        i18next.t(
          "transactions.errors.amountDoedNotEqualTotalAmountPerTaxCategories",
        ),
      );
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function validateParams(param): any {
  return (dispatch, getState): void => {
    const { formData } = getState();

    const currencyAndRateInputField = param.form_fields.find(
      (f) => f.type === "currency_and_rate_input",
    );
    if (!isEmpty(currencyAndRateInputField)) {
      const currencyAndRate = currencyAndRateInputField.form_value;
      validateCurrencyAndRateParams(currencyAndRate);
    }

    const ExpenseField = param.form_fields.find(
      (f) => f.type === "expense_input",
    );
    const amountPerTaxCategoryField = param.form_fields.find(
      (f) => f.type === "amount_per_tax_category_input",
    );
    if (!isEmpty(ExpenseField) && !isEmpty(amountPerTaxCategoryField)) {
      const amountPerTaxCategories = amountPerTaxCategoryField.form_value;
      const amount =
        ExpenseField.form_value.amount === ""
          ? 0
          : ExpenseField.form_value.amount;
      validateAmountPerTaxCategoriesParams(amount, amountPerTaxCategories);
    } else if (
      !isNil(formData.directProductTableId) &&
      !isEmpty(amountPerTaxCategoryField)
    ) {
      const amountPerTaxCategories = amountPerTaxCategoryField.form_value;
      const amount = formData.amount === "" ? 0 : formData.amount;
      validateAmountPerTaxCategoriesParams(amount, amountPerTaxCategories);
    }

    dispatch(validateCostAllocations());
  };
}

export const SUBMIT_FORM = `${prefix}/SUBMIT_FORM`;
export function submitForm(
  isNew,
  authority,
  ownerId,
  isAutoWithholding,
  isMultipleEdit,
  callback,
  lockFormBtn = false,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  return function submitFormThunk(dispatch, getState): void {
    try {
      const {
        formData,
        formState,
        searchBox: { inputs, route, checked },
        allowance,
      } = getState();
      const { directProductTableId } = formData;
      let allowanceTable = null;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let transitParams: any = null;

      if (!isNil(route)) {
        let fare = route.onewayAmount || 0;
        if (checked.roundTrip) {
          fare *= 2;
        }

        transitParams = {
          fare,
          destination: inputs.destination,
          id: route.id,
          isRoundTrip: checked.roundTrip,
        };
      }

      if (!isNil(directProductTableId)) {
        allowanceTable = allowance.tables.find(
          (x) => x.id === directProductTableId,
        );
      }
      const param = collectFieldParams({
        allowanceTable,
        formData: { ...formData, authority, ownerId },
        fields: formState.fields,
        isAutoWithholding,
        isMultipleEdit,
        route: transitParams,
      });
      dispatch(validateParams(param));
      const jsonParam = toExpenseEntryFormRequest(param);

      return dispatch(postFormData(isNew, jsonParam, callback, lockFormBtn));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return dispatch(displayMessage("error", error.message));
    }
  };
}

/*
 * 税区分の一覧を取得する
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fetchTaxCategories(): any {
  return function fetchTaxCategoriesThunk(dispatch, getState): void {
    const { formData } = getState();
    return dispatch(fetchAsync(Api.taxCategories.index)).then((data) => {
      dispatch(setTaxCategories(data));
      dispatch(
        setTaxCategory(data.find((t) => t.name === formData.taxCategoryName)),
      );
    });
  };
}

/*
 * 勘定科目の一覧を取得する
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fetchSuperCategories(): any {
  return function fetchSuperCategoriesThunk(dispatch): void {
    return dispatch(
      fetchAsync(Api.kernels.superCategories.index, { enable: true }),
    )
      .then((data) => dispatch(setSuperCategories(data.superCategories)))
      .catch(() => {
        /* エラー表示後なので、特に何もしない */
      });
  };
}

/*
 * 登録番号から、適格請求書発行事業者を取得する
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fetchVerifyRegistratedNumber(): any {
  return function fetchVerifyRegistratedNumberThunk(dispatch, getState): void {
    const registratedNumber =
      getState().formData.formValues.eligible_invoice_confirmation_input
        .registratedNumber || "";
    dispatch(setIsFetchingInvoicingOrganization(true));

    return dispatch(
      fetchAsync(ApiManagements.invoicingOrganizations.verify, {
        registratedNumber,
      }),
    )
      .then((data) => {
        dispatch(setInvoicingOrganization(data.invoicingOrganization));
        // 適格請求書として扱うフラグの操作
        const asInvoice = ["01", "02"].includes(
          data.invoicingOrganization?.process ?? "",
        );
        dispatch(changeAsEligibleInvoiceWithTaxCategories(asInvoice));
      })
      .catch(() => {
        /* エラー表示後なので、特に何もしない */
      })
      .finally(() => {
        dispatch(setIsFetchingInvoicingOrganization(false));
      });
  };
}

/*
 * レポート名の一覧を取得する
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fetchReportTitles(): any {
  return function fetchReportTitlesThunk(dispatch): void {
    return dispatch(fetchAsync(Api.expenses.reports.openTitles))
      .then((data) => data.titles)
      .then((data) => dispatch(setReportTitles(data)))
      .catch(() => {
        /* エラー表示後なので、特に何もしない */
      });
  };
}

/*
 * 事前申請名の一覧を取得する
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fetchPreReports(): any {
  return (dispatch): void => {
    return dispatch(fetchAsync(Api.preReports.index, { for: "editable" }))
      .then((data) =>
        data.preReports.map((it) => ({
          id: it.id,
          title: it.title,
          department: it.department,
        })),
      )
      .then((data) => dispatch(setPreReports(data)))
      .catch(() => {
        /* エラー表示後なので、特に何もしない */
      });
  };
}

/*
 * 事前申請名の一覧を取得する
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fetchWithholdingConstants(): any {
  return (dispatch): void => {
    return dispatch(fetchAsync(Api.withholdings.calculationConstants))
      .then((data) => dispatch(setWithholdingConstants(data)))
      .catch(() => {
        /* エラー表示後なので、特に何もしない */
      });
  };
}

/*
 * 複数日付入力のチェックボックスのon, offを切り替える
 */
export const MULTI_DATE_INPUT = `${prefix}/MULTI_DATE_INPUT`;
export function checkMultiDate(): AnyAction {
  return {
    type: MULTI_DATE_INPUT,
  };
}

export const CHECK_REUSE_INPUT = `${prefix}/CHECK_REUSE_INPUT`;
export function checkReuseInput(): AnyAction {
  return { type: CHECK_REUSE_INPUT };
}

// Detach
export const DETTACH_TRANSACTION = `${prefix}/DETTACH_TRANSACTION`;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function detachTransaction(onSuccess, onError): any {
  return async function detachTransactionThunk(
    dispatch,
    getState,
  ): Promise<void> {
    const { formData } = getState();
    dispatch(lockFormButton());
    try {
      if (formData.reportId) {
        await dispatch(
          fetchAsync(
            Api.expenses.reports.detach,
            {
              id: formData.reportId,
              transaction_ids: formData.id, // eslint-disable-line camelcase
            },
            true,
          ),
        );
      } else if (formData.preReportId) {
        await dispatch(
          fetchAsync(
            Api.preReports.detach,
            {
              id: formData.preReportId,
              transaction_ids: formData.id, // eslint-disable-line camelcase
            },
            true,
          ),
        );
      }

      if (onSuccess) {
        onSuccess();
      } else {
        dispatch(redirectTo("/"));
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message = getMessageFromResponse(error);
      if (onError) {
        onError(error, message);
        return;
      }
      dispatch(displayMessage("error", message));
    } finally {
      dispatch(unlockFormButton());
    }
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mergeTransaction(withAggregation, onSuccess, onError): any {
  return async (dispatch, getState): Promise<void> => {
    const { formData } = getState();

    dispatch(lockFormButton());

    try {
      await dispatch(
        fetchAsync(
          Api.transactions.merge,
          {
            ids: [formData.id],
            with: withAggregation ? "aggregation" : "transaction",
          },
          true,
        ),
      );

      if (onSuccess) {
        onSuccess();
      } else {
        dispatch(redirectTo("/"));
      }
    } catch (error) {
      const message =
        get(error, "responseJSON.message") ||
        i18next.t("transactions.errors.failedToDelete");
      if (onError) {
        onError(error, message);
      } else {
        dispatch(displayMessage("error", message));
      }
    } finally {
      dispatch(unlockFormButton());
    }
  };
}

// Delete
export const DELETE_TRANSACTION = `${prefix}/DELETE_TRANSACTION`;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deleteTransaction(onSuccess, onError): any {
  return async function deleteTransactionThunk(
    dispatch,
    getState,
  ): Promise<void> {
    const { formData } = getState();
    dispatch(lockFormButton());
    dispatch(closeDeleteModal());
    try {
      await dispatch(
        fetchAsync(Api.transactions.destroy, { id: formData.id }, true),
      );

      if (onSuccess) {
        onSuccess();
      } else {
        dispatch(redirectTo("/"));
      }
    } catch (error) {
      const message =
        get(error, "responseJSON.message") ||
        i18next.t("transactions.errors.failedToDelete");
      if (onError) {
        onError(error, message);
        return;
      }
      dispatch(displayMessage("error", message));
    } finally {
      dispatch(unlockFormButton());
    }
  };
}

// 原本経費のひも付け解除
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function cancelMatching(originalReceiptId): any {
  return (dispatch): void => {
    return dispatch(
      fetchAsync(Api.originalReceipts.cancelMatch, { id: originalReceiptId }),
    ).then(() => {
      dispatch(closeConfirmCancelMatchingModal());
      dispatch(closeCancelReceiptMatchingModal());
      dispatch(setReceiptExpenseMatching());
    });
  };
}

// Toggle
export const TOGGLE_DELETE_MODAL = `${prefix}/TOGGLE_DELETE_MODAL`;
export function openDeleteModal(): AnyAction {
  return {
    type: TOGGLE_DELETE_MODAL,
    show: true,
  };
}

export function closeDeleteModal(): AnyAction {
  return {
    type: TOGGLE_DELETE_MODAL,
    show: false,
  };
}

export const TOGGLE_CONFIRMATION_MODAL = `${prefix}/TOGGLE_CONFIRMATION_MODAL`;
export function openConfirmationModal(): AnyAction {
  return {
    type: TOGGLE_CONFIRMATION_MODAL,
    show: true,
  };
}

export function closeConfirmationModal(): AnyAction {
  return {
    type: TOGGLE_CONFIRMATION_MODAL,
    show: false,
  };
}

export const SET_EMPTY_REQUIRED_FIELDS = `${prefix}/SET_EMPTY_REQUIRED_FIELDS`;
export function onChangeEmptyRequiredFields(emptyRequiredFields): AnyAction {
  return {
    type: SET_EMPTY_REQUIRED_FIELDS,
    emptyRequiredFields,
  };
}

export function resetEmptyRequiredFields(): AnyAction {
  return {
    type: SET_EMPTY_REQUIRED_FIELDS,
    emptyRequiredFields: [],
  };
}

export const TOGGLE_DETACH_MODAL = `${prefix}/TOGGLE_DETACH_MODAL`;
export function openDetachModal(): AnyAction {
  return {
    type: TOGGLE_DETACH_MODAL,
    show: true,
  };
}

export function closeDetachModal(): AnyAction {
  return {
    type: TOGGLE_DETACH_MODAL,
    show: false,
  };
}

export const TOGGLE_CANCEL_RECEIPT_MATCHING_MODAL = `${prefix}/TOGGLE_CANCEL_RECEIPT_MATCHING_MODAL`;
export function openCancelReceiptMatchingModal(): AnyAction {
  return {
    type: TOGGLE_CANCEL_RECEIPT_MATCHING_MODAL,
    show: true,
  };
}

export function closeCancelReceiptMatchingModal(): AnyAction {
  return {
    type: TOGGLE_CANCEL_RECEIPT_MATCHING_MODAL,
    show: false,
  };
}

export const TOGGLE_CONFIRM_DETACH_MODAL = `${prefix}/TOGGLE_CONFIRM_DETACH_MODAL`;
export function openConfirmDetachModal(): AnyAction {
  return {
    type: TOGGLE_CONFIRM_DETACH_MODAL,
    show: true,
  };
}

export function closeConfirmCancelMatchingModal(): AnyAction {
  return {
    type: TOGGLE_CONFIRM_DETACH_MODAL,
    show: false,
  };
}

export const TOGGLE_WITHHOLDING_CONFIRMATION_MODAL = `${prefix}/TOGGLE_WITHHOLDING_CONFIRMATION_MODAL`;
export function openWithholdingConfirmationModal(): AnyAction {
  return {
    type: TOGGLE_WITHHOLDING_CONFIRMATION_MODAL,
    show: true,
  };
}

export function closeWithholdingConfirmationModal(): AnyAction {
  return {
    type: TOGGLE_WITHHOLDING_CONFIRMATION_MODAL,
    show: false,
  };
}

function initTransactedAt(transactedAt, editable): string {
  let initialTransactedAt;

  if (isNil(transactedAt)) {
    if (editable) {
      initialTransactedAt = moment(new Date()).format("YYYY/MM/DD");
    } else {
      initialTransactedAt = "";
    }
  } else if (transactedAt !== "") {
    // 明示的に空にしたい時は空文字列
    initialTransactedAt = moment(new Date(transactedAt) || new Date()).format(
      "YYYY/MM/DD",
    );
  }

  return initialTransactedAt;
}

function resetTransactionParams(transaction): Expense {
  return {
    id: transaction.id,
    ids: transaction.ids,
    transactedAt: initTransactedAt(
      transaction.transactedAt,
      transaction.editable,
    ),
    amount: transaction.amount && +transaction.amount,
    asEligibleInvoice: transaction.asEligibleInvoice,
    originalAmount: transaction.originalAmount,
    originalAmountCurrencyId: transaction.originalAmountCurrencyId,
    exchangeRate: calcApproximateRate(
      transaction.originalAmount,
      transaction.amount,
    ),
    exchangePolicy: transaction.exchangePolicy,
    shopName: transaction.shopName,
    taxCategoryName: transaction.taxCategoryName,
    taxCategory: transaction.taxCategory || null,
    expenseAmountPerTaxCategories:
      transaction.expenseAmountPerTaxCategories || [],
    categoryName: transaction.categoryName,
    parentCategoryName: transaction.parentCategoryName,
    nestedCategoryNames: transaction.nestedCategoryNames,
    nestedCategories: transaction.nestedCategories,
    category: transaction.category || null,
    creditCategoryName: transaction.creditCategoryName,
    creditCategory: transaction.creditCategory || null,
    registratedNumber: transaction.registratedNumber,
    reportTitle: transaction.reportTitle,
    reportId: transaction.reportId,
    preReportTitle: transaction.preReportTitle,
    preReportId: transaction.preReportId,
    project: transaction.project,
    genericFields: transaction.genericFields,
    companions: transaction.companions,
    isCorporate: transaction.isCorporate,
    comment: transaction.comment,
    route: transaction.route,
    editable: transaction.editable,
    status: transaction.status,
    permissions: transaction.permissions,
    ownerId: transaction.ownerId,
    validations: transaction.validations,
    submitOnlyFilledInput: transaction.submitOnlyFilledInput,
    mergeableAggregation: transaction.mergeableAggregation,
    localReceiptFile: {
      foreside: null,
      backside: null,
    },
    receiptImages: transaction.receiptImages,
    directProductTableId: transaction.directProductTableId,
    calculationFormulaVariableInputs:
      transaction.calculationFormulaVariableInputs,
    costAllocations: transaction.costAllocations,
    showDisabledCategories: transaction.showDisabledCategories,
    defaultPeriod: transaction.defaultPeriod,
    invoicingOrganization: transaction.invoicingOrganization,
    paidAddress: transaction.paidAddress,
    inputBy: transaction.inputBy,
    formId: transaction.formId,
    formValues: transaction.formValues,
    receiptExpenseMatching: transaction.receiptExpenseMatching,
    isTaxAmountShow: transaction.isTaxAmountShow,
    isDeleted: transaction.isDeleted,
    transitPayee: transaction.transitPayee,
    createdAt: transaction.createdAt,
  };
}

export const SET_SHOULD_SELECT_SELF_AS_COMPANION = `${prefix}/SET_SHOULD_SELECT_SELF_AS_COMPANION`;
export function setShouldSelectSelfAsCompanion(value): AnyAction {
  return {
    type: SET_SHOULD_SELECT_SELF_AS_COMPANION,
    value,
  };
}

// State Setter
export const RESET_TRANSACTION = `${prefix}/RESET_TRANSACTION`;
export function resetTransaction(transaction): AnyAction {
  return {
    type: RESET_TRANSACTION,
    formData: resetTransactionParams(transaction.formData),
    formState: transaction.formState,
  };
}

export const SET_META_DATA = `${prefix}/SET_META_DATA`;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setMetadata(prop, value, autoInput = false): any {
  return (dispatch, getState): void => {
    const {
      formState: { fields },
    } = getState();

    const constraint = collectConstraint(getState, { [prop]: value });
    dispatch({
      type: SET_META_DATA,
      prop,
      value,
      autoInput,
      defaultValues: filterDefaultValues(fields, constraint, isNil(value)),
    });
  };
}

export const SET_LOCAL_RECEIPT_FILE = `${prefix}/SET_LOCAL_RECEIPT_FILE`;
/**
 *
 * @param {File|null} file
 * @param {boolean} isBackside
 */
export function setLocalReceiptFile(file, isBackside): AnyAction {
  return {
    type: SET_LOCAL_RECEIPT_FILE,
    payload: {
      isBackside,
      file,
    },
  };
}

export const CLEAR_LOCAL_RECEIPT_FILE = `${prefix}/CLEAR_LOCAL_RECEIPT_FILE`;
/**
 *
 * @param {File|null} file
 * @param {boolean} isBackside
 */
export function clearLocalReceiptFile(): AnyAction {
  return {
    type: CLEAR_LOCAL_RECEIPT_FILE,
  };
}

export const RESET_RECEIPT_FILES = `${prefix}/RESET_RECEIPT_FILES`;
/**
 * 領収書ファイルの情報を初期化する.
 * 画像アップロード後に、ローカルのデータではなく、永続化された画像データを表示するために使う.
 *
 * TODO: 領収書のために経費データを取得し直しているが、領収書情報に限らずフォーム内の値を更新後の値でリセットした方が良い
 * @param {string} expenseId
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function resetReceiptFiles(expenseId): any {
  return async (dispatch): Promise<void> => {
    /**
     * NOTE:
     *   領収書は経費更新後にアップロードされるため、更新後の領収書情報を取得するには経費を取得し直すしかない.
     *   （経費一覧は取得し直しているが、更新後の経費が現在の検索条件に掛かる保証がない）
     */

    const expense = await dispatch(
      fetchAsync(Api.transactions.show, { id: expenseId }),
    );

    dispatch({
      type: RESET_RECEIPT_FILES,
      payload: {
        expense,
      },
    });
  };
}

export const DELETE_RECEIPT_FILE = `${prefix}/DELETE_RECEIPT_FILE`;
/**
 *
 * @param {object} receiptFile
 */
export function deleteReceiptFile(receiptFile): AnyAction {
  return {
    type: DELETE_RECEIPT_FILE,
    payload: {
      file: receiptFile,
    },
  };
}

export function setTransactionDate(dateStr): AnyAction {
  return setFormData("transactedAt", dateStr);
}

export function setOriginalAmount(amount): AnyAction {
  return setFormData("originalAmount", amount);
}

export function setAmount(amount): AnyAction {
  return setFormData("amount", amount);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setCurrency(currency, fieldType = "expense_input"): any {
  return (dispatch): void => {
    if (isNil(currency)) {
      dispatch(setFormData("originalAmountCurrencyId", null));
      dispatch(clearExchangeSetting());
      if (fieldType === "currency_and_rate_input") {
        // formDataからcurrencyIdやexchageRateの入力情報を丸ごとリセットする
        dispatch(setFormData("currency_and_rate_input", null));
      }
      return;
    }
    dispatch(setFormData("originalAmountCurrencyId", currency.currencyId));
    if (fieldType === "currency_and_rate_input") {
      // 一括更新時のみセットする
      dispatch(setCurrencyIdForMultipleEditing(currency.currencyId));
      return;
    }
    dispatch(resetRateSettings(currency.currencyId));
  };
}

export const SET_EXCEPT_UNKNOWN_CURRENCY = `${prefix}/SET_EXCEPT_UNKNOWN_CURRENCY`;
export function setExceptUnknownCurrency(exceptUnknownCurrency): AnyAction {
  return {
    type: SET_EXCEPT_UNKNOWN_CURRENCY,
    exceptUnknownCurrency,
  };
}

export const SET_WITHHOLDING_CONSTANTS = `${prefix}/SET_WITHHOLDING_CONSTANTS`;
export function setWithholdingConstants(value = {}): AnyAction {
  return {
    type: SET_WITHHOLDING_CONSTANTS,
    value,
  };
}

export const SET_IS_EDITING_WITHHOLDING_CATEGORY = `${prefix}/SET_IS_EDITING_WITHHOLDING_CATEGORY`;
export function setIsEditingWithholdingCategory(value = false): AnyAction {
  return {
    type: SET_IS_EDITING_WITHHOLDING_CATEGORY,
    value,
  };
}

export const SET_IS_FETCHING_INVOICING_ORGANIZATION = `${prefix}/SET_IS_FETCHING_INVOICING_ORGANIZATION`;
export function setIsFetchingInvoicingOrganization(value = false): AnyAction {
  return {
    type: SET_IS_FETCHING_INVOICING_ORGANIZATION,
    value,
  };
}

export const SET_CURRENCIES = `${prefix}/SET_CURRENCIES`;
export function setCurrencies(defaultCurrency, exchangeRates): AnyAction {
  const currencies = [
    defaultCurrency,
    ...exchangeRates.map((c) => ({
      currencyId: c.currencyId,
      currencyName: c.currencyName,
    })),
  ];

  return {
    type: SET_CURRENCIES,
    value: { total: currencies, current: currencies },
  };
}

export const SET_EXCHANGE_RATE_PREF = `${prefix}/SET_EXCHANGE_RATE_PREF`;
export function setExchangeRatePref(defaultCurrency, exchangeRates): AnyAction {
  return {
    type: SET_EXCHANGE_RATE_PREF,
    defaultCurrencyId: defaultCurrency.currencyId,
    exchangeRates,
  };
}

export function selectAllowanceTable(
  allowanceTable: { id: string } | null = null,
): AnyAction {
  // 通常の経費入力においては、allowanceTableはnull
  return setFormData(
    "directProductTableId",
    allowanceTable && allowanceTable.id,
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function reloadCurrencies(currencyId, dateStr): any {
  return async (dispatch, getState): Promise<void> => {
    await dispatch(fetchCurrencies(dateStr));

    const { suggestions, formData } = getState();
    const currencies = suggestions.currencies.current;
    const originalAmountCurrencyId = formData.originalAmountCurrencyId;
    const currency = currencies.find((c) => c.currencyId === currencyId);

    if (isNil(currency)) {
      const defaultCurrency = currencies.find(
        (c) => c.currencyId === originalAmountCurrencyId,
      );
      dispatch(setCurrency(defaultCurrency));
    } else {
      dispatch(setCurrency(currency));
    }
    dispatch(setTransactionDate(dateStr));
  };
}

// dateStrは複数日をカンマ区切りで渡すことも可能
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fetchCurrencies(dateStr): any {
  return async (dispatch, getState): Promise<void> => {
    const { formData, formState } = getState();
    const baseCurrencyId = formState.defaultCurrencyId;
    const originalAmountCurrencyId = formData.originalAmountCurrencyId;

    /** 渡された dateStr を JST として読んだ後、実行環境におけるタイムゾーンを考慮した日時に置換した日付 */
    const localDateTimes = dateStr.split(",").map((x) => {
      return new Date(
        `${x.replace(/\//g, "-")}T00:00:00+09:00`,
      ).toLocaleDateString("ja-JP");
    });

    return dispatch(
      fetchAsync(
        Api.currencyPreferences.index,
        snakecaseKeys({ date: localDateTimes.join(), baseCurrencyId }),
      ),
    )
      .then((data) => {
        // 経費登録の場合はXXXを非表示、経費編集の場合はXXXを表示
        const exchangeRates = selectProperExchangeRates(
          data.exchangeRates,
          localDateTimes,
          getState().formState.exceptUnknownCurrency,
        );
        dispatch(setCurrencies(data.defaultCurrency, exchangeRates));
        dispatch(setExchangeRatePref(data.defaultCurrency, exchangeRates));
        const currencyId =
          originalAmountCurrencyId || data.defaultCurrency.currencyId;
        dispatch(setFormData("originalAmountCurrencyId", currencyId));
      })
      .catch((error) =>
        dispatch(displayMessage("error", getMessageFromResponse(error))),
      );
  };
}

function selectProperExchangeRates(
  exchangeRates,
  dateStrs,
  exceptUnknown,
): unknown[] {
  const dates = dateStrs.map((x) => new Date(x).setHours(0));

  return exchangeRates
    .filter((currency) => !exceptUnknown || currency.currencyId !== "XXX")
    .filter((currency) => {
      const properPeriodRates = currency.rateSettings.filter((rateSetting) => {
        const startDate = new Date(rateSetting.startDate).setHours(0);
        const expiryDate = new Date(rateSetting.expiryDate).setHours(0);

        return dates.every((date) => startDate <= date && date <= expiryDate);
      });

      return !isNil(properPeriodRates);
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function clearExchangeSetting(): any {
  return (dispatch, getState): void => {
    dispatch(setExchangeRate(null));
    dispatch(setExchangePolicy(null));
    const {
      formData: { originalAmount },
    } = getState();

    // 通貨を切替後 amount を元に戻す
    dispatch(setAmount(toNumber(originalAmount)));
  };
}

// 経費の作成・更新時 or 経費の一括更新時の通貨レートセット処理
export const SET_EXCHANGE_RATE = `${prefix}/SET_EXCHANGE_RATE`;
export function setExchangeRate(rate, key = "expense_input"): AnyAction {
  return {
    type: SET_EXCHANGE_RATE,
    value: isNil(rate) ? "" : rate,
    key,
  };
}

// 経費の一括更新時の通貨IDセット処理
export const SET_CURRENCY_ID_MULTIPLE_EDITING = `${prefix}/SET_CURRENCY_ID_MULTIPLE_EDITING`;
export function setCurrencyIdForMultipleEditing(
  currencyIdForMultipleEditing,
): AnyAction {
  return {
    type: SET_CURRENCY_ID_MULTIPLE_EDITING,
    value: isNil(currencyIdForMultipleEditing)
      ? ""
      : currencyIdForMultipleEditing,
  };
}

// 経費の一括更新時の通貨レートの指定タイプのセット処理
export const SET_RATE_REFERENCE_TYPE_MULTIPLE_EDITING = `${prefix}/SET_RATE_REFERENCE_TYPE_MULTIPLE_EDITING`;
export function setRateReferenceTypeForMultipleEditing(
  referenceType,
): AnyAction {
  return {
    type: SET_RATE_REFERENCE_TYPE_MULTIPLE_EDITING,
    value: referenceType,
  };
}

export const AUTO_SET_WITHHOLDING = `${prefix}/AUTO_SET_WITHHOLDING`;
export function autoSetWithholding(value): AnyAction {
  return {
    type: AUTO_SET_WITHHOLDING,
    value,
  };
}

export const SET_EXCHANGE_POLICY = `${prefix}/SET_EXCHANGE_POLICY`;
export function setExchangePolicy(policy, key = "expense_input"): AnyAction {
  return {
    type: SET_EXCHANGE_POLICY,
    value: policy,
    key,
  };
}

export const RESET_RATE_SETTINGS = `${prefix}/RESET_RATE_SETTINGS`;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function resetRateSettings(currencyId): any {
  return (dispatch, getState): void => {
    const { formData, formState } = getState();
    const { originalAmount } = formData;
    const { defaultCurrencyId, exchangeRates } = formState;
    const exchangeRateObj = exchangeRates.find(
      (x) => x.currencyId === currencyId,
    );

    if (isNil(exchangeRateObj) || exchangeRateObj.rateSettings.length === 0) {
      dispatch(clearExchangeSetting());
      return;
    }

    const hasSameRates = !!exchangeRateObj.rateSettings.reduce((a, b) =>
      a.exchangeRate === b.exchangeRate ? a.exchangeRate : NaN,
    );
    // レートの設定期間が重複したり、複数日選択した場合、異なるレートが存在する
    if (!hasSameRates) {
      dispatch(clearExchangeSetting());
      return;
    }

    const { exchangeRate, exchangePolicy } = exchangeRateObj.rateSettings[0];
    dispatch(setExchangeRate(exchangeRate));
    dispatch(setExchangePolicy(exchangePolicy));
    const calculatedAmount = calcAmount(
      originalAmount,
      exchangeRate,
      exchangePolicy,
      defaultCurrencyId,
    );
    dispatch(setAmount(calculatedAmount));
  };
}

export function changeAsEligibleInvoiceWithTaxCategories(
  newAsEligibleInvoice,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  return (dispatch, getState): void => {
    const {
      formData: { asEligibleInvoice },
    } = getState();
    const currentAsEligibleInvoice = asEligibleInvoice;

    // 「適格請求書として扱う」の値が変更されない場合、何もしない
    // (例: 「適格請求書として『扱う』」状態で登録番号を入力し、適格請求書発行事業者情報を取得。その適格請求書発行事業者が『適格』であった場合)
    if (currentAsEligibleInvoice === newAsEligibleInvoice) {
      return;
    }

    dispatch(setAsEligibleInvoice(newAsEligibleInvoice));
  };
}

export const SET_REGISTRATED_NUMBER = `${prefix}/SET_REGISTRATED_NUMBER`;
export function setRegistratedNumber(registratedNumber): AnyAction {
  return {
    type: SET_REGISTRATED_NUMBER,
    value: registratedNumber,
  };
}

export const SET_INVOICING_ORGANIZATION = `${prefix}/SET_INVOICING_ORGANIZATION`;
export function setInvoicingOrganization(invoicingOrganization): AnyAction {
  return {
    type: SET_INVOICING_ORGANIZATION,
    value: invoicingOrganization,
  };
}

export const SET_PAID_ADDRESS = `${prefix}/SET_PAID_ADDRESS`;
export function setPaidAddress(paidAddress): AnyAction {
  return {
    type: SET_PAID_ADDRESS,
    value: paidAddress,
  };
}

export function setDestination(destination): AnyAction {
  return setFormData("destination", destination);
}

export function setShopName(shopName): AnyAction {
  return setFormData("shopName", shopName);
}

export function setOriginByCategory(
  originByCategory,
  destinationByCategory,
): AnyAction {
  return setFormData("originAndDestination", {
    originByCategory,
    destinationByCategory,
  });
}

export function setDestinationByCategory(
  destinationByCategory,
  originByCategory,
): AnyAction {
  return setFormData("originAndDestination", {
    destinationByCategory,
    originByCategory,
  });
}

export function setVisitByCategory(visitByCategory): AnyAction {
  return setFormData("visitByCategory", visitByCategory);
}

export function setPurposeByCategory(purposeByCategory): AnyAction {
  return setFormData("purposeByCategory", purposeByCategory);
}

export function exchangeOriginAndDestination(
  originByCategory,
  destinationByCategory,
): AnyAction {
  return setFormData("originAndDestination", {
    originByCategory: destinationByCategory,
    destinationByCategory: originByCategory,
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setTaxCategoryName(taxCategoryName = ""): any {
  return (dispatch, getState): void => {
    dispatch(setFormData("taxCategoryName", taxCategoryName));

    const taxCategories = getState().suggestions.taxCategories.total;
    const taxCategory =
      taxCategories.find((c) => c.name === taxCategoryName) || null;

    dispatch(setTaxCategory(taxCategory));
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setCreditCategoryName(creditCategoryName = ""): any {
  return (dispatch, getState): void => {
    dispatch(setFormData("creditCategoryName", creditCategoryName));

    const superCategories = getState().suggestions.superCategories.total;
    const creditCategory = superCategories.find(
      (c) => c.name === creditCategoryName,
    );
    dispatch(setCreditCategory(creditCategory));
  };
}

export function setCreditCategory(creditCategory): AnyAction {
  return setFormData("creditCategory", creditCategory);
}

/**
 * 入力されたIDに一致する費用負担部署を、設定値として設定します
 * @param {string} id - 費用負担部署のID
 * @param {number} index - 複数存在するうちの何個目の費用負担部署に対する変更なのか
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function updateCostAllocationsById(id, index): any {
  return (dispatch, getState): void => {
    const updateTarget = getState().formData.costAllocations[index];
    const payerDept = getState().suggestions.groups.total.find(
      (dept) => dept.id === id,
    );

    const nextCostAllocations = getState().formData.costAllocations;
    nextCostAllocations[index] = {
      payerId: payerDept?.id,
      payerName: payerDept?.name,
      payerType: "Group",
      numerator: updateTarget.numerator,
      denominator: updateTarget.denominator,
    };

    dispatch(setCostAllocations(nextCostAllocations));
  };
}

/**
 * 入力された文字に一致する費用負担部署を、設定値として設定します
 * @node 同名の部署が存在する場合に選択対象が曖昧になってしまいます
 * @param {string} input - ユーザの入力した費用負担部署名
 * @param {number} index - 複数存在するうちの何個目の費用負担部署に対する変更なのか
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function updateCostAllocationsByInput(input, index): any {
  return (dispatch, getState): void => {
    const groups = getState().suggestions.groups.total;

    const costAllocations = getState().formData.costAllocations.map((x, i) => {
      if (i === index) {
        if (input === "") {
          return {
            ...x,
            payerId: null,
            payerName: "",
          };
        }

        const group = groups.find((g) => g.name === input);

        if (!isNil(group)) {
          return {
            payerId: group.id,
            payerName: group.name,
            payerType: "Group",
            numerator: x.numerator,
            denominator: x.denominator,
          };
        }

        return { ...x, payerName: input };
      }

      return x;
    });

    dispatch(setCostAllocations(costAllocations));
  };
}

// 負担率を百分率に変換
function allocationByPercentage(costAllocations): unknown[] {
  return costAllocations.map((x) => {
    const allocation = math.fraction(x.numerator, x.denominator);

    return {
      ...x,
      numerator: +math.multiply(allocation, 100),
      denominator: 100,
    };
  });
}

// 選択されていないgroupだけをcurrentにする
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function pruneGroupSuggestion(current = []): any {
  return (dispatch, getState): void => {
    const { formData, suggestions } = getState();
    let formerCurrent = current;
    if (current.length === 0) {
      formerCurrent = suggestions.groups.current;
    }

    const filteredCurrent = filterNotSelectedGroupSuggestion(
      formerCurrent,
      formData.costAllocations,
    );
    dispatch(updateGroupSuggestions(filteredCurrent));
  };
}

function filterNotSelectedGroupSuggestion(total, costAllocations): unknown[] {
  const payerNames = costAllocations.map((x) => x.payerName);
  const current = total.filter((x) => !payerNames.includes(x.name));

  return current;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function appendCostAllocations(): any {
  return (dispatch, getState): void => {
    const { costAllocations } = getState().formData;
    dispatch(
      setCostAllocations(
        costAllocations.concat({
          payerType: "Group",
          payerId: null,
          numerator: 0,
          denominator: 100,
        }),
      ),
    );
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeCostAllocations(index): any {
  return (dispatch, getState): void => {
    const { formData } = getState();
    dispatch(
      setCostAllocations(
        formData.costAllocations.filter((x, i) => i !== index),
      ),
    );
  };
}

// 負担率を等分する
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function devideCostAllocationsEquelly(): any {
  return (dispatch, getState): void => {
    const { costAllocations } = getState().formData;
    const numerator = 1;
    const denominator = costAllocations.length;
    const nextCostAllocations = costAllocations.map((x) => ({
      ...x,
      numerator,
      denominator,
    }));

    dispatch(setCostAllocations(nextCostAllocations));
  };
}

export function setCostAllocations(costAllocations): AnyAction {
  return setFormData(
    "costAllocations",
    allocationByPercentage(costAllocations),
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setNumerator(numerator, index): any {
  return (dispatch, getState): void => {
    const { costAllocations } = getState().formData;

    dispatch(
      setCostAllocations(
        costAllocations.map((x, i) => {
          if (i === index) {
            return { ...x, numerator: +numerator };
          }
          return x;
        }),
      ),
    );
  };
}

export function setReportTitle(reportTitle = ""): AnyAction {
  return setFormData("reportTitle", reportTitle);
}

export function setPreReport(preReport): AnyAction {
  return setFormData("preReport", preReport);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setPreReportWithDepartment(preReport): any {
  return (dispatch): void => {
    dispatch(setPreReport({ id: preReport?.id, title: preReport?.title }));

    // 事前申請に紐づく所属部署に変更
    if (!isNil(preReport)) {
      dispatch(
        setDepartmentWithCostAllocations(preReport.department || {}, false),
      );
    } else {
      dispatch(
        setDepartmentWithCostAllocations(
          get(userPreferences, "department"),
          false,
        ),
      );
    }
  };
}

export function setProject(project: Project | null = null): AnyAction {
  return setFormData("project", project);
}

export function checkCorporate(isChecked = false): AnyAction {
  return setFormData("isCorporate", isChecked);
}

export function checkElectronicReceiptImage(isChecked = false): AnyAction {
  return setFormData("isElectronicReceiptImage", isChecked);
}

export function setTransitPayee(transitPayee): AnyAction {
  return setFormData("transitPayee", transitPayee);
}

export function setWithholding(withholding): AnyAction {
  return setFormData("withholding", withholding);
}

export function setAddress(address): AnyAction {
  return setFormData("address", address);
}

export function setFullName(name): AnyAction {
  return setFormData("fullName", name);
}

export function setGenericFields(genericFields = []): AnyAction {
  return setFormData("genericFields", genericFields);
}

export function setCompanions(companions = []): AnyAction {
  return setFormData("companions", companions);
}

export function setReceiptExpenseMatching(
  receiptExpenseMatching = null,
): AnyAction {
  return setFormData("receiptExpenseMatching", receiptExpenseMatching);
}

export function setAssignableReport(
  assignableReport: AssignableReport | null = null,
): AnyAction {
  return setFormData("assignableReport", assignableReport);
}

export const ROTATE_RECEIPT_FILE = `${prefix}/ROTATE_RECEIPT_FILE`;
export function rotateReceiptFile(file, rotation): AnyAction {
  return {
    type: ROTATE_RECEIPT_FILE,
    payload: { file, rotation },
  };
}

export const SET_TAX_CATEGORIES = `${prefix}/SET_TAX_CATEGORIES`;
export function setTaxCategories(taxCategories = []): AnyAction {
  return {
    type: SET_TAX_CATEGORIES,
    histories: taxCategories,
  };
}

export const SET_SUPER_CATEGORIES = `${prefix}/SET_SUPER_CATEGORIES`;
export function setSuperCategories(
  superCategories: SuperCategory[] = [],
): AnyAction {
  return {
    type: SET_SUPER_CATEGORIES,
    histories: superCategories,
  };
}

export const SET_GROUPS = `${prefix}/SET_GROUPS`;
export function setGroups(groups: Department[] = []): AnyAction {
  return {
    type: SET_GROUPS,
    histories: groups,
  };
}

export const SET_USER_DEPARTMENTS = `${prefix}/SET_USER_DEPARTMENTS`;
export function setUserDepartments(
  groups: DepartmentWithAbsolutePath[] = [],
): AnyAction {
  return {
    type: SET_USER_DEPARTMENTS,
    histories: groups,
  };
}

export const SET_REPORT_TITLES = `${prefix}/SET_REPORT_TITLES`;
export function setReportTitles(reportTitles = []): AnyAction {
  return {
    type: SET_REPORT_TITLES,
    histories: reportTitles,
  };
}

export const SET_PRE_REPORTS = `${prefix}/SET_PRE_REPORTS`;
export function setPreReports(preReports = []): AnyAction {
  return {
    type: SET_PRE_REPORTS,
    histories: preReports,
  };
}

/*
 * プロジェクトが選択可能かどうかをチェックする
 */
export const SET_PROJECT_PREF = `${prefix}/SET_PROJECT_PREF`;
export function setProjectPref(isSelectable = false): AnyAction {
  return {
    type: SET_PROJECT_PREF,
    show: isSelectable,
  };
}

/*
 * 複数日付選択をさせるために、Date Pickerを逐次描画している
 * その描画が完了しているかどうかを設定する
 */
export const SET_DATE_PICKER_STATUS = `${prefix}/SET_DATE_PICKER_STATUS`;
export function setDatePickerStatus(value): AnyAction {
  return {
    type: SET_DATE_PICKER_STATUS,
    value,
  };
}

/*
 * 複数日付選択のチェックボックスの状態を切り替える
 */
export const SWITCH_MULTI_DATE = `${prefix}/SWITCH_MULTI_DATE`;
export function switchMultiDate(): AnyAction {
  return { type: SWITCH_MULTI_DATE };
}

export const LOCK_FORM_BUTTON = `${prefix}/LOCK_FORM_BUTTON`;
export function lockFormButton(): AnyAction {
  return {
    type: LOCK_FORM_BUTTON,
  };
}

export const UNLOCK_FORM_BUTTON = `${prefix}/UNLOCK_FORM_BUTTON`;
export function unlockFormButton(): AnyAction {
  return {
    type: UNLOCK_FORM_BUTTON,
  };
}

// Suggestions

export const UPDATE_TAX_CATEGORY_SUGGESTIONS = `${prefix}/UPDATE_TAX_CATEGORY_SUGGESTIONS`;
export function updateTaxCategorySuggestions(
  taxCategories: unknown[] = [],
): AnyAction {
  return updateSuggestions(UPDATE_TAX_CATEGORY_SUGGESTIONS, taxCategories);
}

export const UPDATE_CREDIT_CATEGORY_SUGGESTIONS = `${prefix}/UPDATE_CREDIT_CATEGORY_SUGGESTIONS`;
export function updateCreditCategorySuggestions(
  categories: unknown[] = [],
): AnyAction {
  return updateSuggestions(UPDATE_CREDIT_CATEGORY_SUGGESTIONS, categories);
}

export const UPDATE_GROUP_SUGGESTIONS = `${prefix}/UPDATE_GROUP_SUGGESTIONS`;
export function updateGroupSuggestions(groups: unknown[] = []): AnyAction {
  return updateSuggestions(UPDATE_GROUP_SUGGESTIONS, groups);
}

export const UPDATE_REPORT_TITLE_SUGGESTIONS = `${prefix}/UPDATE_REPORT_TITLE_SUGGESTIONS`;
export function updateReportTitleSuggestions(
  reportTitles: unknown[] = [],
): AnyAction {
  return updateSuggestions(UPDATE_REPORT_TITLE_SUGGESTIONS, reportTitles);
}

export const RESET_TAX_CATEGORY_SUGGESTIONS = `${prefix}/RESET_TAX_CATEGORY_SUGGESTIONS`;
export function resetTaxCategorySuggestions(): AnyAction {
  return { type: RESET_TAX_CATEGORY_SUGGESTIONS };
}

export const RESET_CREDIT_CATEGORY_SUGGESTIONS = `${prefix}/RESET_CREDIT_CATEGORY_SUGGESTIONS`;
export function resetCreditCategorySuggestions(): AnyAction {
  return { type: RESET_CREDIT_CATEGORY_SUGGESTIONS };
}

export const RESET_REPORT_TITLE_SUGGESTIONS = `${prefix}/RESET_REPORT_TITLE_SUGGESTIONS`;
export function resetReportTitleSuggestions(): AnyAction {
  return { type: RESET_REPORT_TITLE_SUGGESTIONS };
}

export const REQUEST_TAX_CATEGORY_SUGGESTIONS_UPDATE = `${prefix}/REQUEST_TAX_CATEGORY_SUGGESTIONS_UPDATE`;
export function requestTaxCategorySuggestionsUpdate({
  value,
  reason,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): any {
  return requestSuggestionsUpdate(
    REQUEST_TAX_CATEGORY_SUGGESTIONS_UPDATE,
    value,
    reason,
  );
}

export const REQUEST_CREDIT_CATEGORY_SUGGESTIONS_UPDATE = `${prefix}/REQUEST_CREDIT_CATEGORY_SUGGESTIONS_UPDATE`;
export function requestCreditCategorySuggestionsUpdate({
  value,
  reason,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): any {
  return requestSuggestionsUpdate(
    REQUEST_CREDIT_CATEGORY_SUGGESTIONS_UPDATE,
    value,
    reason,
  );
}

export const REQUEST_REPORT_TITLE_SUGGESTIONS_UPDATE = `${prefix}/REQUEST_REPORT_TITLE_SUGGESTIONS_UPDATE`;
export function requestReportTitleSuggestionsUpdate({
  value,
  reason,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): any {
  return requestSuggestionsUpdate(
    REQUEST_REPORT_TITLE_SUGGESTIONS_UPDATE,
    value,
    reason,
  );
}

export const REQUEST_GROUP_SUGGESTIONS_UPDATE = `${prefix}/REQUEST_GROUP_SUGGESTIONS_UPDATE`;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function requestGroupSuggestionsUpdate({ value, reason }): any {
  return requestSuggestionsUpdate(
    REQUEST_GROUP_SUGGESTIONS_UPDATE,
    value,
    reason,
  );
}

/*
 * @returns {string} - レポート名の初期値
 */
export function getDefaultReportTitle(): string {
  const date = new Date();
  const padding = date.getMonth() < 9 ? "0" : "";
  return `${date.getFullYear()}年${padding}${date.getMonth() + 1}月分`;
}

export function calcAmount(
  originalAmount,
  exchangeRate,
  exchangePolicy,
  currencyId,
): number {
  const currencyOption = initIntlCurrencyObj(currencyId).resolvedOptions();

  // マイナス記号や小数点のみが渡ってくる場合がある
  const invalidText = ["-", ".", "-."];
  if (!originalAmount || invalidText.includes(originalAmount)) {
    return 0;
  }

  // exchangeRateはnullまたは空文字になることがある
  const amount = new Decimal(originalAmount)
    .times(isNil(exchangeRate) || exchangeRate === "" ? 1 : exchangeRate)
    .toNumber();

  let fn = floor;

  switch (exchangePolicy) {
    case "ceil":
      fn = ceil;
      break;
    case "round":
      fn = round;
      break;
    default:
      break;
  }
  return fn(amount, currencyOption.maximumFractionDigits || 0);
}

// 概算に使用するだけで、サーバに送信しない情報のため、正確な計算はしない
export function calcApproximateRate(originalAmount, amount): number {
  const rate = amount / originalAmount;
  return isNaN(rate) || !isFinite(rate) ? 0 : round(rate, 3);
}

export function getEmptyCostAllocations(): unknown[] {
  return [
    {
      payerType: "Group",
      payerId: null,
      payerName: "",
      numerator: 100,
      denominator: 100,
    },
  ];
}

export function setDepartment(department): AnyAction {
  return setFormData("department", department);
}

export function setDepartmentWithCostAllocations(
  department,
  isMultipleEditing,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  return (dispatch): void => {
    // 複数まとめて編集の場合のみ所属部署フォームを空欄可能とする
    if (isMultipleEditing || !isNil(department)) {
      dispatch(setDepartment(department));
    }

    // 費用負担部署を再セット
    if (!isNil(department) && !isEmpty(department)) {
      dispatch(
        setCostAllocations([
          {
            denominator: 100,
            numerator: 100,
            payerId: department.id,
            payerType: "Group",
            payerName: department.name,
          },
        ]),
      );
    } else {
      dispatch(setCostAllocations(getEmptyCostAllocations()));
    }
  };
}

/**
 * 税区分別金額の設定
 * @param {AmountPerTaxCategory[]} amountPerTaxCategories - 税区分別金額一覧
 */
export function setAmountPerTaxCategories(amountPerTaxCategories): AnyAction {
  return setFormData("expenseAmountPerTaxCategories", amountPerTaxCategories);
}

export const SET_OPERATOR_ENTRY = `${prefix}/SET_OPERATOR_ENTRY`;
/**
 * オペレータ入力を設定します。
 * @param {OperatorEntry} operatorEntry - オペレータ入力結果
 */
const setOperatorEntry = (operatorEntry): AnyAction => {
  return {
    type: SET_OPERATOR_ENTRY,
    payload: {
      operatorEntry,
    },
  };
};

/**
 * オペレータ入力を取得します。
 * @param {string} expenseId - 経費ID
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchOperatorEntry = (expenseId): any => {
  return async (dispatch): Promise<void> => {
    try {
      const data = await fetchOperatorEntryApi(expenseId);
      dispatch(setOperatorEntry(data));
    } catch {
      // エラーをユーザに知らせない。
    }
  };
};

export const OPEN_OPERATOR_ENTRY_CONFIRM_MODAL = `${prefix}/OPEN_OPERATOR_ENTRY_CONFIRM_MODAL`;
/**
 * オペレータ入力結果を確認するモーダルを開きます。
 */
export const openOperatorEntryConfirmModal = (): AnyAction => {
  return {
    type: OPEN_OPERATOR_ENTRY_CONFIRM_MODAL,
  };
};

export const CLOSE_OPERATOR_ENTRY_CONFIRM_MODAL = `${prefix}/CLOSE_OPERATOR_ENTRY_CONFIRM_MODAL`;
/**
 * オペレータ入力結果を確認するモーダルを閉じます。
 */
export const closeOperatorEntryConfirmModal = (): AnyAction => {
  return {
    type: CLOSE_OPERATOR_ENTRY_CONFIRM_MODAL,
  };
};

/* -------------------------------------------------------------------------- */
/* 経費科目に関するメソッドが依存しているモジュール群                         */
/* -------------------------------------------------------------------------- */

// import { displayMessage } from "actions/ActionCreators";
// import { fetchAsyncAll } from "actions/AsyncAction";
// import escapeRegExp from "lodash/escapeRegExp";
// import get from "lodash/get";
// import isEmpty from "lodash/isEmpty";
// import isNil from "lodash/isNil";
// import { canRead } from "../utilities/transactionFormPolicy";
// import Api from "utilities/api";
// import { getMessageFromResponse, snakecaseKeys } from "utilities/Utils";
// import * as memoBuilder from "../utilities/transactionMemoBuilder";

/* -------------------------------------------------------------------------- */
/* 経費科目に関するメソッドが依存しているメソッド群                           */
/* -------------------------------------------------------------------------- */

export const SET_FORM_DATA = `${prefix}/SET_FORM_DATA`;
export function setFormData(prop, value, defaultValues = null): AnyAction {
  return {
    type: SET_FORM_DATA,
    prop,
    value,
    defaultValues,
  };
}

/*
 * 入力の補完候補を更新する
 * @param {string} type - actionのtypeを指定。更新対象を区別する
 * @param {array} suggestions - 補完候補
 */
export function updateSuggestions(
  type,
  suggestions: unknown[] = [],
  index = null,
): AnyAction {
  return { type, suggestions, index };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function requestSuggestionsUpdate(type, value, reason): any {
  return (dispatch, getState): void => {
    const { suggestions } = getState();

    // 補完候補から選択された時と、入力がリセットされた時に、補完候補を初期化する
    if (
      reason === "click" ||
      reason === "enter" ||
      isNil(value) ||
      value.length === 0
    ) {
      if (type === REQUEST_TAX_CATEGORY_SUGGESTIONS_UPDATE) {
        dispatch(resetTaxCategorySuggestions());
      } else if (type === REQUEST_CATEGORY_SUGGESTIONS_UPDATE) {
        dispatch(resetCategorySuggestions());
      } else if (type === REQUEST_CREDIT_CATEGORY_SUGGESTIONS_UPDATE) {
        dispatch(resetCreditCategorySuggestions());
      } else if (type === REQUEST_GROUP_SUGGESTIONS_UPDATE) {
        const total = suggestions.groups.total;
        dispatch(updateGroupSuggestions(total));
      } else if (type === REQUEST_REPORT_TITLE_SUGGESTIONS_UPDATE) {
        dispatch(resetReportTitleSuggestions());
      }
      return;
    }

    let total = [];
    if (type === REQUEST_TAX_CATEGORY_SUGGESTIONS_UPDATE) {
      total = suggestions.taxCategories.total;
      dispatch(
        updateTaxCategorySuggestions(
          filterSuggestions(total, value, (it) => it.name),
        ),
      );
    } else if (type === REQUEST_CATEGORY_SUGGESTIONS_UPDATE) {
      total = suggestions.categories.total;
      dispatch(
        updateCategorySuggestions(
          filterSuggestions(total, value, (it) => it.name),
        ),
      );
    } else if (type === REQUEST_CREDIT_CATEGORY_SUGGESTIONS_UPDATE) {
      total = suggestions.superCategories.total;
      dispatch(
        updateCreditCategorySuggestions(
          filterSuggestions(total, value, (it) => it.name),
        ),
      );
    } else if (type === REQUEST_GROUP_SUGGESTIONS_UPDATE) {
      total = suggestions.groups.total;
      dispatch(
        updateGroupSuggestions(
          filterSuggestions(total, value, (it) => it.name),
        ),
      );
    } else if (type === REQUEST_REPORT_TITLE_SUGGESTIONS_UPDATE) {
      total = suggestions.reportTitles.total;
      dispatch(updateReportTitleSuggestions(filterSuggestions(total, value)));
    }
  };
}

/*
 * 補完候補を部分一致検索によりfilterする
 */
export function filterSuggestions(
  total,
  value,
  getter = (x): string => x,
): unknown[] {
  const reg = new RegExp(`.*${escapeRegExp(value.trim())}.*`, "gi");
  return total.filter((x) => getter(x).match(reg));
}

const collectConstraint = (getState, inputValue): unknown => {
  const {
    formData: { status, authority, formValues },
  } = getState();
  return {
    status,
    authority,
    ...formValues,
    ...inputValue,
  };
};

const filterDefaultValues = (fields, constraint, empty = false): unknown => {
  const overwrittenTypes: string[] = [];
  return fields.reduce((valueObj, f) => {
    const key = f.id || f.type;
    const canReadField = canRead(f, Object.assign(constraint));

    if (canReadField && !isNil(f.defaultValue) && (!f.edited || !f.editable)) {
      if (f.type === "date_input") {
        return {
          ...valueObj,
          [key]: f.defaultValue
            ? moment(f.defaultValue).format("YYYY/MM/DD")
            : null,
        };
      }

      // 適格請求書確認に関するプロパティは、経費科目が変更・リセットされた後でも、現状入力されている値を保持し続ける
      if (f.type === "eligible_invoice_confirmation_input") {
        return valueObj;
      }

      return { ...valueObj, [key]: f.defaultValue };
    }

    if (
      !empty &&
      (f.type === "cost_allocation_input" || f.type === "project_input")
    ) {
      if (canReadField) {
        overwrittenTypes.push(f.type);

        return omit(valueObj, [key]);
      }
      if (!overwrittenTypes.includes(f.type)) {
        return { ...valueObj, [key]: f.defaultValue };
      }
    }

    return valueObj;
  }, {});
};

export const SET_AS_ELIGIBLE_INVOICE = `${prefix}/SET_AS_ELIGIBLE_INVOICE`;
export function setAsEligibleInvoice(asEligibleInvoice): AnyAction {
  return {
    type: SET_AS_ELIGIBLE_INVOICE,
    value: asEligibleInvoice,
  };
}

export function setTaxCategory(taxCategory): AnyAction {
  return setFormData("taxCategory", taxCategory);
}

export function setComment(comment): AnyAction {
  return setFormData("comment", comment);
}

/* -------------------------------------------------------------------------- */
/* 経費科目に関するメソッド群                                                 */
/* -------------------------------------------------------------------------- */

/* 経費科目に関する Action Creator */

export const SET_NESTED_CATEGORIES = `${prefix}/SET_NESTED_CATEGORIES`;
export function setNestedCategories(nestedCategories): AnyAction {
  return {
    type: SET_NESTED_CATEGORIES,
    nestedCategories,
  };
}

export const SET_NESTED_CATEGORY_NAMES = `${prefix}/SET_NESTED_CATEGORY_NAMES`;
export function setNestedCategoryNames(nestedCategoryNames): AnyAction {
  return {
    type: SET_NESTED_CATEGORY_NAMES,
    nestedCategoryNames,
  };
}

export const SET_NESTED_CATEGORY_NAME = `${prefix}/SET_NESTED_CATEGORY_NAME`;
export function setNestedCategoryName(categoryName, level): AnyAction {
  return {
    type: SET_NESTED_CATEGORY_NAME,
    value: categoryName,
    level,
  };
}

export const SET_NESTED_CATEGORY = `${prefix}/SET_NESTED_CATEGORY`;
export function setNestedCategory(category, level): AnyAction {
  return {
    type: SET_NESTED_CATEGORY,
    value: category,
    level,
  };
}

export const SET_CATEGORIES = `${prefix}/SET_CATEGORIES`;
export function setCategories(categories: PersonalCategory[] = []): AnyAction {
  return {
    type: SET_CATEGORIES,
    histories: categories,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setCategory(category, defaultValues: any = null): AnyAction {
  return setFormData("category", category, defaultValues);
}

export const UPDATE_CATEGORY_SUGGESTIONS = `${prefix}/UPDATE_CATEGORY_SUGGESTIONS`;
export function updateCategorySuggestions(
  categories: unknown[] = [],
): AnyAction {
  return updateSuggestions(UPDATE_CATEGORY_SUGGESTIONS, categories);
}

export const SET_ALL_NESTED_CATEGORY_SUGGESTIONS = `${prefix}/SET_ALL_NESTED_CATEGORY_SUGGESTIONS`;
export function setAllNestedCategorySuggestions(nestedCategories): AnyAction {
  return {
    type: SET_ALL_NESTED_CATEGORY_SUGGESTIONS,
    nestedCategories,
  };
}

export const SET_NESTED_CATEGORY_SUGGESTIONS = `${prefix}/SET_NESTED_CATEGORY_SUGGESTIONS`;
export function setNestedCategorySuggestions(categories, level): AnyAction {
  return {
    type: SET_NESTED_CATEGORY_SUGGESTIONS,
    histories: categories,
    level,
  };
}

export const RESET_NESTED_CATEGORY_SUGGESTIONS = `${prefix}/RESET_NESTED_CATEGORY_SUGGESTIONS`;
export function resetNestedCategorySuggestions(level = 1): AnyAction {
  return {
    type: RESET_NESTED_CATEGORY_SUGGESTIONS,
    level,
  };
}

export const REQUEST_CATEGORY_SUGGESTIONS_UPDATE = `${prefix}/REQUEST_CATEGORY_SUGGESTIONS_UPDATE`;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function requestCategorySuggestionsUpdate({ value, reason }): any {
  return requestSuggestionsUpdate(
    REQUEST_CATEGORY_SUGGESTIONS_UPDATE,
    value,
    reason,
  );
}

export const SET_NESTED_CATEGORIES_BY_PARENT_ID = `${prefix}/SET_NESTED_CATEGORIES_BY_PARENT_ID`;
export function setNestedCategoriesByParentId(
  nestedCategoriesByParentId: CategoriesByParentId,
): AnyAction {
  return {
    type: SET_NESTED_CATEGORIES_BY_PARENT_ID,
    value: nestedCategoriesByParentId,
  };
}

export const REQUEST_NESTED_CATEGORY_SUGGESTIONS_UPDATE = `${prefix}/REQUEST_NESTED_CATEGORY_SUGGESTIONS_UPDATE`;
export function requestNestedCategorySuggestionsUpdate(
  { value, reason },
  level,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  return requestSuggestionsOfArrayUpdate(
    REQUEST_CATEGORY_SUGGESTIONS_UPDATE,
    value,
    reason,
    level,
  );
}

export const RESET_CATEGORY_SUGGESTIONS = `${prefix}/RESET_CATEGORY_SUGGESTIONS`;
export function resetCategorySuggestions(): AnyAction {
  return { type: RESET_CATEGORY_SUGGESTIONS };
}

export const UPDATE_NESTED_CATEGORY_SUGGESTIONS = `${prefix}/UPDATE_NESTED_CATEGORY_SUGGESTIONS`;
export function updateNestedCategorySuggestions(
  nestedCategories: unknown[] = [],
  level,
): AnyAction {
  return {
    type: UPDATE_NESTED_CATEGORY_SUGGESTIONS,
    suggestions: nestedCategories,
    level,
  };
}

export const RESET_NESTED_CATEGORY_SUGGESTIONS_OF = `${prefix}/RESET_NESTED_CATEGORY_SUGGESTIONS_OF`;
export function resetNestedCategorySuggestionsOf(level): AnyAction {
  return { type: RESET_NESTED_CATEGORY_SUGGESTIONS_OF, level };
}

export const REQUEST_PARENT_CATEGORY_SUGGESTIONS_UPDATE = `${prefix}/REQUEST_PARENT_CATEGORY_SUGGESTIONS_UPDATE`;
export function requestParentCategorySuggestionsUpdate({
  value,
  reason,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): any {
  return requestSuggestionsUpdate(
    REQUEST_PARENT_CATEGORY_SUGGESTIONS_UPDATE,
    value,
    reason,
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function requestSuggestionsOfArrayUpdate(type, value, reason, level): any {
  return (dispatch, getState): void => {
    const { suggestions } = getState();

    // 補完候補から選択された時と、入力がリセットされた時に、補完候補を初期化する
    if (
      reason === "click" ||
      reason === "enter" ||
      isNil(value) ||
      value.length === 0
    ) {
      dispatch(resetNestedCategorySuggestionsOf(level));
      return;
    }

    let total = [];
    total = suggestions.nestedCategories[level].total;
    dispatch(
      updateNestedCategorySuggestions(
        filterSuggestions(total, value, (it) => it.name),
        level,
      ),
    );
  };
}

/** 経費科目の API コール と Dispatcher */

/**
 * fetchCategories
 * 経費科目の一覧を取得する
 * @param {boolean} isNew
 */
export function fetchCategories(
  isNew = false,
  includeNotSelectable = false,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  return async (dispatch, getState): Promise<void> => {
    const {
      formData,
      formState: { fields },
    } = getState();
    const formId = get(formData, "formId", "normal");

    try {
      const { data } = await fetchAsyncAll(
        (params) =>
          Api.personalCategories
            .index(
              snakecaseKeys({
                enable: !get(formData, "showDisabledCategories", false),
                ownerId: get(formData, "ownerId", null),
                formId:
                  formId === "allowance"
                    ? get(formData, "directProductTableId", null)
                    : formId,
                forPreReport: !isEmpty(get(formData, "preReportTitle")),
                taxCategory: true, // 選択された経費科目の税区分を利用している部分があるので、これは必要
                includeNotSelectable,
                ...params,
              }),
            )
            .then((result) => {
              return { data: result.categories, count: result.count };
            }),
        {
          /* APIを投げる際の追加パラメータは特になし */
        },
        { limit: 200 },
      );

      // NOTE: APIで取得した経費科目は「使用する」設定である。
      // 経費に登録された経費科目が「使用しない」設定に変更されている場合、
      // 選択欄に表示されないため、I/FをAPIのレスポンスに合わせ、
      // 先頭に追加することで、選択欄に表示する仕様とした。
      const currentCategory = get(formData, "formValues.category_input", null);
      if (currentCategory && data.every((d) => d.id !== currentCategory.id)) {
        currentCategory.parnetId = null;
        currentCategory.selectable = true;
        currentCategory.level = 0;
        currentCategory.enable = false;
        data.unshift(currentCategory);
      }

      dispatch(setCategories(data));

      const nestedCategoriesByParentId = buildCategoriesByParentId(data);
      dispatch(setNestedCategoriesByParentId(nestedCategoriesByParentId));

      const category = data.find(
        (c) => c.id === get(formData, "formValues.category_input.id"),
      );

      if (category) {
        const nestedCategories = getParentCategoriesAndSelf(category, data);
        dispatch(
          setAllNestedCategorySuggestions(
            getNestedSuggestions(category, data, nestedCategoriesByParentId),
          ),
        );
        dispatch(setNestedCategories(nestedCategories));
        dispatch(setNestedCategoryNames(nestedCategories.map((c) => c.name)));
      } else {
        dispatch(
          setNestedCategorySuggestions(nestedCategoriesByParentId.top || [], 0),
        );
      }

      const constraint = collectConstraint(getState, {
        category_input: category, // eslint-disable-line camelcase
      });
      const defaultValues = isNil(formData.id)
        ? filterDefaultValues(fields, constraint, isNil(category))
        : null;
      const defaultCategory = get(defaultValues, "category_input", null);
      dispatch(
        setCategory(
          category || currentCategory,
          isNil(defaultCategory) ? {} : { category_input: defaultCategory }, // eslint-disable-line camelcase
        ),
      );

      if (
        defaultCategory?.notRequiresReceivingInvoice === true || // 交通費の新規作成時時
        (category?.notRequiresReceivingInvoice === true && isNew) // 事前申請からの経費の新規作成時
      ) {
        dispatch(setAsEligibleInvoice(true));
      }

      if (isNew) {
        dispatch(setTaxCategory(get(category, "taxCategory")));

        const comment = get(formData, "comment", null);
        const commentFormat = get(
          userPreferences.preference,
          "commentFormat",
          null,
        );
        const memoTemplate = get(category, "memoTemplate", null);

        dispatch(
          setComment(
            memoBuilder.buildMemo(comment, commentFormat, memoTemplate),
          ),
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(displayMessage("error", getMessageFromResponse(error)));
    }
  };
}

/** 経費科目に関する Dispatcher */

export function setCategoryName(
  categoryName = "",
  isAsEliglbleInvoiceChangePolicy = "change_to_false",
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  return (dispatch, getState): void => {
    const {
      formState: { fields },
      formData: { asEligibleInvoice },
    } = getState();

    dispatch(setFormData("categoryName", categoryName));

    const categories = getState().suggestions.categories.total;
    let newCategory = null;
    if (categoryName.length) {
      newCategory = categories.find((c) => c.name === categoryName) || null;
    }

    const constraint = collectConstraint(getState, {
      category_input: newCategory, // eslint-disable-line camelcase
    });

    dispatch(
      setCategory(
        newCategory,
        filterDefaultValues(fields, constraint, isNil(newCategory)),
      ),
    );

    if (isAsEliglbleInvoiceChangePolicy === "change_to_true") {
      dispatch(setAsEligibleInvoice(true));
    } else if (isAsEliglbleInvoiceChangePolicy === "change_to_false") {
      dispatch(setAsEligibleInvoice(false));
    } else if (isAsEliglbleInvoiceChangePolicy === "keep_current") {
      dispatch(setAsEligibleInvoice(asEligibleInvoice));
    }
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function sliceNestedCategories(nestedCategories, end): any {
  return (dispatch): void => {
    return dispatch(setNestedCategories(nestedCategories.slice(0, end)));
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function sliceNestedCategoryNames(nestedCategoryNames, end): any {
  return (dispatch): void => {
    return dispatch(setNestedCategoryNames(nestedCategoryNames.slice(0, end)));
  };
}

export function sliceNestedCategory(
  nestedCategories,
  nestedCategoryNames,
  clearLevel,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  return (dispatch): void => {
    dispatch(sliceNestedCategories(nestedCategories, clearLevel));
    dispatch(sliceNestedCategoryNames(nestedCategoryNames, clearLevel));
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function selectNestedCategory(categoryName = "", level): any {
  return (dispatch, getState): void => {
    const { formData, suggestions } = getState();

    dispatch(setNestedCategoryName(categoryName, level));
    const categories = suggestions.nestedCategories[level].total;
    let category: { id: string } | null = null;
    let childCategories = null;
    if (categoryName.length) {
      category = categories.find((c) => c.name === categoryName) || null;

      if (category !== null) {
        childCategories = suggestions.nestedCategoriesByParentId[category.id];
      }

      const formerCategoryId = get(formData.nestedCategories[level], "id");
      const currentCategoryId = get(category, "id");
      if (formerCategoryId !== currentCategoryId) {
        // 変更があれば、小項目の入力フォームをクリアする
        dispatch(
          sliceNestedCategory(
            formData.nestedCategories,
            formData.nestedCategoryNames,
            level + 1,
          ),
        );
        dispatch(resetNestedCategorySuggestions(level + 1));
      }

      dispatch(setNestedCategory(category, level));
    } else {
      // 自らと小項目以下の入力フォームをクリアする
      dispatch(
        sliceNestedCategory(
          formData.nestedCategories,
          formData.nestedCategoryNames,
          level,
        ),
      );
    }

    if (!isNil(childCategories)) {
      dispatch(setNestedCategorySuggestions(childCategories, level + 1));
    }
  };
}
