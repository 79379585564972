import { ReorganizationsChangeConfirmModal } from "applications/kernels/organizations/reorganizations/compornents/ConfirmModal";
import { DateInput } from "applications/payment_requests/components/ReportRegister/parts/ReportInfo/components/DateInput";
import i18next from "i18n";
import React from "react";
import styled from "styled-components";
import { useHooks } from "./hooks";

const Title = styled.div`
  font-weight: bold;
`;
const Items = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

interface Props {
  isVisible: boolean;
  handleClickReserve: (date: string) => void;
  handleClose: () => void;
}

/**
 * 組織マスタ変更予約: 予約モーダル
 */
export const ReorganizationsChangeReserveModal: React.FC<Props> = (
  props,
): JSX.Element => {
  const { date, handleChange, handleClickApply } = useHooks(
    props.handleClickReserve,
  );

  return (
    <ReorganizationsChangeConfirmModal
      isVisible={props.isVisible}
      applyText={i18next.t(
        "reorganizations.changeDetail.actions.reserveButton",
      )}
      title={i18next.t("reorganizations.changeDetail.modal.reserveModal.title")}
      disabled={!date}
      handleClose={props.handleClose}
      handleClickApply={handleClickApply}
    >
      <Items>
        <Title>
          {i18next.t(
            "reorganizations.changeDetail.modal.reserveModal.scheduledAt",
          )}
        </Title>

        <DateInput
          value={date}
          disabled={false}
          calendarPlacement="bottom-start"
          nextMonthShortcut={true}
          onChange={handleChange}
        />
      </Items>
    </ReorganizationsChangeConfirmModal>
  );
};
