import React from "react";
import { StyledButton } from "./Button.styles";
import { ButtonProps } from "./Button.types";

interface Props extends ButtonProps {
  loading?: boolean;
}

export const Button: React.FC<Props> = ({
  children,
  variant = "primary",
  size = "default",
  loading = false,
  ...props
}) => {
  return (
    <StyledButton
      variant={variant}
      size={size}
      {...props}
      disabled={props.disabled || loading}
    >
      {loading ? (
        <i
          className="fas fa-lg fa-spin fa-spinner loading-icon"
          style={{ margin: "3px 0" }}
        />
      ) : (
        children
      )}
    </StyledButton>
  );
};
