import SimpleModal, { ButtonProps } from "components/SimpleModal";
import i18next from "i18n";
import React from "react";

interface Props {
  isVisible: boolean;
  children: JSX.Element;
  title: string;
  applyText: string;
  disabled: boolean;
  handleClickApply: () => void;
  handleClose: () => void;
}

/**
 * 組織マスタ変更予約: 確認モーダル
 */
export const ReorganizationsChangeConfirmModal: React.FC<Props> = (
  props,
): JSX.Element => {
  const buttons: ButtonProps[] = [
    {
      color: "default",
      content: i18next.t("commons.actions.cancel"),
      onClick: props.handleClose,
    },
    {
      color: "primary",
      disabled: props.disabled,
      content: props.applyText,
      onClick: props.handleClickApply,
    },
  ];

  return (
    <SimpleModal
      show={props.isVisible}
      close={props.handleClose}
      title={props.title}
      buttons={buttons}
    >
      {props.children}
    </SimpleModal>
  );
};
