import * as CustomSuggestActionTypes from "containers/actions/customSuggest";
import autoSuggest from "containers/reducers/autoSuggest";
import get from "lodash/get";
import * as ActionTypes from "../actions";
import { Suggestion, Suggestions } from "../types/suggestions";

function typeToKey(type): string {
  switch (type) {
    case ActionTypes.SET_TAX_CATEGORIES:
    case ActionTypes.UPDATE_TAX_CATEGORY_SUGGESTIONS:
    case ActionTypes.RESET_TAX_CATEGORY_SUGGESTIONS:
      return "taxCategories";
    case ActionTypes.UPDATE_REPORT_TITLE_SUGGESTIONS:
    case ActionTypes.SET_REPORT_TITLES:
    case ActionTypes.RESET_REPORT_TITLE_SUGGESTIONS:
      return "reportTitles";
    case ActionTypes.SET_CATEGORIES:
    case ActionTypes.UPDATE_CATEGORY_SUGGESTIONS:
    case ActionTypes.RESET_CATEGORY_SUGGESTIONS:
      return "categories";
    case ActionTypes.SET_NESTED_CATEGORY_SUGGESTIONS:
    case ActionTypes.UPDATE_NESTED_CATEGORY_SUGGESTIONS:
    case ActionTypes.RESET_NESTED_CATEGORY_SUGGESTIONS_OF:
      return "nestedCategories";
    case ActionTypes.SET_SUPER_CATEGORIES:
    case ActionTypes.UPDATE_CREDIT_CATEGORY_SUGGESTIONS:
    case ActionTypes.RESET_CREDIT_CATEGORY_SUGGESTIONS:
      return "superCategories";
    case ActionTypes.SET_PRE_REPORTS:
      return "preReports";
    case ActionTypes.SET_GROUPS:
    case ActionTypes.UPDATE_GROUP_SUGGESTIONS:
      return "groups";
    case ActionTypes.SET_USER_DEPARTMENTS:
      return "userDepartments";
    default:
      return "";
  }
}

function updateTotal<T>(arr: T[]): Suggestion<T> {
  return {
    total: [...arr],
    current: [...arr],
  };
}

function updateCurrent<T>(state, arr: T[]): Suggestion<T> {
  return {
    ...state,
    current: [...arr],
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function arrayWithItemAt(index, item, array): any {
  return new Array(Math.max(array.length, index + 1)).fill(null).map((x, i) => {
    if (i === index) {
      return item;
    }

    return array[i] || null;
  });
}

const initialState: Suggestions = {
  taxCategories: { total: [], current: [] },
  categories: { total: [], current: [] },
  nestedCategories: [{ total: [], current: [] }],
  superCategories: { total: [], current: [] },
  reportTitles: { total: [], current: [] },
  groups: { total: [], current: [] },
  preReports: [],
  currencies: { total: [], current: [] },
  nestedCategoriesByParentId: {},
};

const suggestions = (state = initialState, action): Suggestions => {
  const key = get(action.field, "id", null) || typeToKey(action.type);
  switch (action.type) {
    case ActionTypes.SET_PRE_REPORTS:
      return {
        ...state,
        preReports: action.histories,
      };
    case ActionTypes.SET_TAX_CATEGORIES:
    case ActionTypes.SET_CATEGORIES:
    case ActionTypes.SET_SUPER_CATEGORIES:
    case ActionTypes.SET_GROUPS:
    case ActionTypes.SET_USER_DEPARTMENTS:
    case ActionTypes.SET_REPORT_TITLES:
    case CustomSuggestActionTypes.SET_CUSTOM_INPUT_LIST:
      return {
        ...state,
        [key]: updateTotal(action.histories),
      };
    case ActionTypes.SET_NESTED_CATEGORY_SUGGESTIONS:
      return {
        ...state,
        nestedCategories: arrayWithItemAt(
          action.level,
          updateTotal(action.histories),
          state[key],
        ),
      };
    case ActionTypes.SET_ALL_NESTED_CATEGORY_SUGGESTIONS:
      return {
        ...state,
        nestedCategories: action.nestedCategories,
      };
    case ActionTypes.RESET_NESTED_CATEGORY_SUGGESTIONS:
      // 最上層以外は動的に決まるため取り除く
      return {
        ...state,
        nestedCategories: state.nestedCategories.slice(0, action.level),
      };
    case ActionTypes.UPDATE_TAX_CATEGORY_SUGGESTIONS:
    case ActionTypes.UPDATE_CATEGORY_SUGGESTIONS:
    case ActionTypes.UPDATE_CREDIT_CATEGORY_SUGGESTIONS:
    case ActionTypes.UPDATE_GROUP_SUGGESTIONS:
    case ActionTypes.UPDATE_REPORT_TITLE_SUGGESTIONS:
    case CustomSuggestActionTypes.UPDATE_CUSTOM_INPUT_SUGGESTIONS:
      return {
        ...state,
        [key]: updateCurrent(state[key], action.suggestions),
      };
    case ActionTypes.UPDATE_NESTED_CATEGORY_SUGGESTIONS:
      return {
        ...state,
        nestedCategories: arrayWithItemAt(
          action.level,
          updateCurrent(state[key][action.level], action.suggestions),
          state[key],
        ),
      };
    case ActionTypes.RESET_TAX_CATEGORY_SUGGESTIONS:
    case ActionTypes.RESET_CATEGORY_SUGGESTIONS:
    case ActionTypes.RESET_CREDIT_CATEGORY_SUGGESTIONS:
    case ActionTypes.RESET_REPORT_TITLE_SUGGESTIONS:
    case CustomSuggestActionTypes.RESET_CUSTOM_INPUT_SUGGESTIONS:
      return {
        ...state,
        [key]: updateCurrent(state[key], get(state[key], "total", [])),
      };
    case ActionTypes.RESET_NESTED_CATEGORY_SUGGESTIONS_OF:
      return {
        ...state,
        nestedCategories: arrayWithItemAt(
          action.level,
          updateCurrent(
            state[key][action.level],
            get(state[key][action.level], "total", []),
          ),
          state[key],
        ),
      };
    case ActionTypes.SET_CURRENCIES:
      return {
        ...state,
        currencies: action.value,
      };
    case ActionTypes.SET_NESTED_CATEGORIES_BY_PARENT_ID: {
      return {
        ...state,
        nestedCategoriesByParentId: action.value,
      };
    }
    default:
      return autoSuggest(state, action);
  }
};

export default suggestions;
