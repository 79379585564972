import AutoSuggestWrapper from "components/auto_suggest_wrapper";
import React from "react";
import { PersonalCategory } from "utilities/api/models";
import {
  OnSuggestionSelectedParams,
  OnSuggestionsUpdateRequestedUpdatedValue,
} from "../types";

interface Props {
  readonly inputPropsClassName: string;
  readonly value: string;
  readonly suggestions: PersonalCategory[];
  readonly onTextChanged: (text: string) => void;
  readonly onSuggestionsUpdateRequested: (
    value: OnSuggestionsUpdateRequestedUpdatedValue,
  ) => void;
  readonly onSuggestionSelected: (
    e: React.FormEvent<HTMLInputElement>,
    params: OnSuggestionSelectedParams,
  ) => void;
  readonly clearValue: () => void;
  readonly disabled: boolean;
}

export const CategorySelector: React.FC<Props> = ({
  inputPropsClassName,
  value,
  suggestions,
  onTextChanged,
  onSuggestionsUpdateRequested,
  onSuggestionSelected,
  clearValue,
  disabled,
}) => {
  return (
    <AutoSuggestWrapper
      isImmutable
      inputProps={{ className: inputPropsClassName }}
      value={value}
      suggestions={suggestions}
      getSuggestionValue={(v): string => v.name}
      onSuggestionsUpdateRequested={onSuggestionsUpdateRequested}
      onTextChanged={onTextChanged}
      onSuggestionSelected={onSuggestionSelected}
      clearValue={clearValue}
      disabled={disabled}
    />
  );
};
