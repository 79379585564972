import { MenuItem } from "components/renewaled_ui/Dropdown";
import i18next from "i18n";
import React, { useCallback } from "react";
import styled from "styled-components";
import ApiAdi from "utilities/api_adi";
import flash from "utilities/flash";
import { snakecaseKeys } from "utilities/Utils";
import { openWorkerInputCorrectionRequestForm } from "./openWorkerInputCorrectionRequestForm";

interface Props {
  readonly expenseId: string;
  readonly ownerId: string;
  readonly dataInputCompletedAt: string;
}

const Icon = styled.i`
  margin-left: 5px;
`;

/**
 * 経費詳細画面上から自動入力修正依頼のGoogleフォームを開くためのメニュー
 */
export const WorkerInputCorrectionRequestMenu: React.FC<Props> = ({
  expenseId,
  ownerId,
  dataInputCompletedAt,
}) => {
  const handleOpenWorkerInputCorrectionRequestForm = useCallback(async () => {
    const params = snakecaseKeys({ type: "tk", documentId: expenseId });
    const data = await ApiAdi.inquiryForms.index(params);
    if (!data) {
      flash.error("修正依頼フォームの取得に失敗しました。");
      return;
    }

    if (!data) {
      flash.error("修正依頼フォームの取得に失敗しました。");
      return;
    }

    openWorkerInputCorrectionRequestForm(
      expenseId,
      ownerId,
      dataInputCompletedAt,
      data,
    );
  }, [expenseId, ownerId, dataInputCompletedAt]);

  return (
    <MenuItem onClick={handleOpenWorkerInputCorrectionRequestForm}>
      {i18next.t("expenses.workerInputCorrectionRequest.button")}
      <Icon className="fas fa-external-link-alt" />
    </MenuItem>
  );
};
