import * as allowanceActions from "applications/allowances/actions/transaction";
import { ExpenseHistoryModal } from "applications/expenses/history/components/ExpenseHistoryModal";
import { SplitStartDropdownButton } from "applications/expenses/split/components/buttons/SplitStartDropdownButton";
import { SplitModal } from "applications/expenses/split/components/SplitModal";
import { isSplittableExpense } from "applications/expenses/split/utilities";
import * as transactionActions from "applications/transactions/actions/transactionTable";
import {
  canDelete,
  canDetach,
  canUpdateAll,
} from "applications/transactions/utilities/transactionFormPolicy";
import EmbeddedFlash from "components/embedded_flash";
import { Button } from "components/renewaled_ui/buttons";
import {
  Dropdown,
  DropdownToggleButton,
  MenuItem,
} from "components/renewaled_ui/Dropdown";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  RightDrawer,
  RightDrawerFooter,
  RightDrawerHeader,
} from "components/renewaled_ui/single_views";
import SimpleModal from "components/SimpleModal";
import i18next from "i18n";
import get from "lodash/get";
import isNil from "lodash/isNil";
import omit from "lodash/omit";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import transactionType from "types/transaction";
import Formatter from "utilities/formatter";
import { isExpenseCreatableAndRequestableMember } from "utilities/Utils";
import * as actions from "../../transactions/actions";
import * as markingAsMatchedActionCreators from "../../transactions/actions/MarkingAsMatched/ActionCreators";
import ConfirmOriginalReceiptButton from "../../transactions/components/ConfirmOriginalReceiptButton";
import MarkingAsMatchedButton from "../../transactions/components/MarkingAsMatchedButton";
import Transaction from "../../transactions/components/Transaction";
import CancelReceiptMatchingModal from "../../transactions/containers/CancelReceiptMatchingModal";
import MarkingAsMatchedModalContainer from "../../transactions/containers/MarkingAsMatchedModalContainer";
import TransactionForm from "../../transactions/containers/TransactionForm";
import { WorkerInputCorrectionRequestMenu } from "./WorkerInputCorrectionRequestMenu";

const CreateModalView = styled(Modal)`
  display: flex;
  padding: 30px 20px;

  .modal-dialog {
    max-height: calc(100vh - 60px);
    margin: auto;
  }

  .modal-content {
    max-height: 100%;
  }

  .modal-body {
    height: calc(100% - 50px - 54px);
    position: relative;
    max-height: unset;

    .transaction-form {
      height: 100%;
    }
  }
`;

/** 新規作成と一括編集はモーダル、編集と閲覧は右ドロワーで表示するSingleView */
export class ExpenseSingleViewer extends Component {
  constructor(props) {
    super(props);

    this.detachExpense = this.detachExpense.bind(this);
    this.handleSplitModalClose = this.handleSplitModalClose.bind(this);
    this.onClickCancelMatchingButton =
      this.onClickCancelMatchingButton.bind(this);
    this.onClickOpenCancelReceiptMatchingModalButton =
      this.onClickOpenCancelReceiptMatchingModalButton.bind(this);
    this.onClickOpenMarkingAsMatchedModal =
      this.onClickOpenMarkingAsMatchedModal.bind(this);
    this.onSelectStartSplitDropdown =
      this.onSelectStartSplitDropdown.bind(this);
    this.onDestroyError = this.onDestroyError.bind(this);
    this.onDestroySuccess = this.onDestroySuccess.bind(this);
    this.onDetachSuccess = this.onDetachSuccess.bind(this);
    this.onMerge = this.onMerge.bind(this);
    this.onUnread = this.onUnread.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    // refs
    this.embeddedFlash = React.createRef();

    /**
     * Modal.body の中身は `componentWillMount` で描画されるが、 `componentDidMount` で描画される前提で実装されているため、 `state.shown` を使って描画タイミングをHackする.
     *
     * @see {@link https://github.com/react-bootstrap/react-overlays/pull/229} ReactOverlaysの更新 (0.8.0 -> 0.9.0) による仕様変更への対応
     */
    this.handleShowModal = this.handleShowModal.bind(this);
    this.state = {
      shown: false,
      showExpenseHistoryModal: false,
      showSplitModal: false,
      separatorType: "",
      transactedAt: "",
      shopName: "",
      amount: "",
    };
  }

  componentDidMount() {
    this.props.fetchRemoteData(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show === true && this.props.show === false) {
      // modalが閉じられるときにmodal bodyを消す
      this.setState({ ...this.state, shown: false });
    }

    // 表示経費を変えた際にヘッダータイトルに表示する文字列を更新
    if (prevProps.id !== this.props.id) {
      this.setState({
        ...this.state,
        transactedAt: this.props.transactedAt,
        shopName: this.props.shopName,
        amount: this.props.amount,
      });
    }
  }

  /* ***
   * 経費が選択され、かつ経費の作成者と参照者が異なる (承認時など) 場合, 再度直積表をフェッチする必要がある.
   * その際に、経費作成時に使用された値を引き継ぐようにする。
   */
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.id !== nextProps.id &&
      nextProps.ownerId !== userPreferences.id
    ) {
      const beforeInputValue = nextProps.calculationFormulaVariableInputs;

      this.props.fetchRemoteData(nextProps).then(() => {
        const nextAllowanceTable = this.props.allowance.tables.find(
          (table) => table.id === this.props.directProductTableId,
        );
        if (nextAllowanceTable) {
          this.props.resetAllowanceInput(
            nextAllowanceTable,
            beforeInputValue,
            this.props.defaultPeriod,
          );
        }
      });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps) {
    const switchToAnotherExpense = this.props.id !== nextProps.id; // 経費詳細ドロワー  選択されている経費が切り替わった時
    const switchToAnotherAllowanceCreateForm =
      this.props.directProductTableId !== nextProps.directProductTableId; // 経費一覧 > 日当 新規作成モーダル  作成する日当の種類を切り替えた時
    const fetchingAllowancesDelayedBehindFetchingExpenses =
      this.props.allowance.tables.length === 0 &&
      nextProps.allowance.tables.length > 0; // (1) 経費一覧 (api/v1/transactions) のレスポンス取得  (2) 経費詳細ドロワーを開く (3) 日当一覧 (api/v1/direct_product_tables) のレスポンスの取得、の順序となった時 (※)現在、日当一覧取得のパフォーマンスに課題があるために発生。今後対処予定
    if (
      switchToAnotherExpense ||
      switchToAnotherAllowanceCreateForm ||
      fetchingAllowancesDelayedBehindFetchingExpenses
    ) {
      const allowanceTable = nextProps.allowance.tables.find(
        (table) => table.id === nextProps.directProductTableId,
      );

      if (allowanceTable) {
        this.props.resetAllowanceInput(
          allowanceTable,
          nextProps.calculationFormulaVariableInputs,
          this.props.defaultPeriod,
        );
      }
    }

    if (nextProps.show === false && this.props.show === true) {
      this.setState({ ...this.state, shown: false });
    }
  }

  handleShowModal() {
    this.setState({
      ...this.state,
      shown: true,
      transactedAt: this.props.transactedAt,
      shopName: this.props.shopName,
      amount: this.props.amount,
    });
  }

  /**
   * 経費移動ボタンを表示するかどうか。
   * 基本的には、経費一覧上の経費を表示していて、かつ移動が可能な場合に表示する。
   *
   * @returns {boolean} 表示する時trueを返す
   */
  shouldRenderExpenseCursor() {
    if (this.props.isNew) {
      // 新規作成時は、経費移動ボタンを押すと入力データが全部消えるので表示しない
      return false;
    }

    if (!this.props.id) {
      // 一括編集時。経費一覧上の経費を表示している状態ではないので、経費移動ボタンを表示しない
      return false;
    }

    // 移動が可能な場合（callbackが設定されている時）のみ表示
    // 具体的には、テーブルに1件しか経費がない時、及びテーブル外でモーダルを使用（編集履歴一覧）している時に、経費移動ボタンを表示しない
    return !!(this.props.onGoToPreviousExpense || this.props.onGoToNextExpense);
  }

  onUpdate(e) {
    e.preventDefault();
    if (this.embeddedFlash.current) this.embeddedFlash.current.clear();
    this.refs.transactionForm.handleSubmit(e);
  }

  onUnread(e) {
    e.preventDefault();
    this.props.onUnread(this.props.id);
  }

  // 原本確認ボタン押下で原本経費のひも付け解除のモーダルを開く
  onClickOpenCancelReceiptMatchingModalButton(e) {
    e.preventDefault();
    this.props.openCancelReceiptMatchingModal();
  }

  // 原本経費のひも付け解除ボタン
  onClickCancelMatchingButton(originalReceiptId) {
    this.props.cancelMatching(originalReceiptId).then(() => {
      this.props.onUpdateSuccess();
    });
  }

  // 「原本確認済にステータスを更新する」ボタン押下で強制原本確認済設定のモーダルを開く
  onClickOpenMarkingAsMatchedModal(e) {
    e.preventDefault();
    this.props.openMarkingAsMatchedModal();
  }

  /**
   * 分割ボタン押下で経費分割モーダルを開く
   */
  onSelectStartSplitDropdown(separatorType) {
    this.setState({ ...this.state, showSplitModal: true, separatorType });
  }

  onMerge(e) {
    e.preventDefault();
    this.embeddedFlash.current.clear();
    this.refs.transactionForm.handleMerge(e);
  }

  onSubmit(options, expenses, error, message) {
    if (!expenses) {
      // エラー発生時。ただし、領収書アップロードに失敗した時は除く
      if (this.embeddedFlash.current && error && message) {
        this.embeddedFlash.current.error(message);
      }
      return;
    }

    if (this.props.isNew) {
      this.props.onCreateSuccess(expenses, options);
    } else {
      this.props.onUpdateSuccess(expenses);
      // 経費を保存した際にヘッダータイトルに表示する文字列を更新
      this.setState({
        ...this.state,
        transactedAt: this.props.transactedAt,
        shopName: this.props.shopName,
        amount: this.props.amount,
      });
    }
  }

  onCompleteSavingRotation() {
    this.embeddedFlash.current.success(
      i18next.t("transactions.messages.saveRotation"),
    );
  }

  onErrorSavingRotation() {
    this.embeddedFlash.current.error(
      i18next.t("transactions.errors.failedToSaveRotation"),
    );
  }

  onDestroySuccess() {
    this.props.onDestroySuccess();
  }

  onDestroyError(error, message) {
    this.embeddedFlash.current.error(message);
  }

  onDetachSuccess() {
    this.props.onDetachSuccess();
  }

  detachExpense() {
    this.props.detachTransaction(this.onDetachSuccess);
  }

  /**
   * 経費分割モーダルを閉じる場合
   */
  handleSplitModalClose() {
    this.setState({ ...this.state, showSplitModal: false, separatorType: "" });
  }

  get isRequester() {
    return isExpenseCreatableAndRequestableMember();
  }

  renderBody() {
    const editPerformable =
      this.isRequester &&
      this.props.editable &&
      canUpdateAll(this.props.permissions) &&
      !this.props.isDeleted;

    if (editPerformable) {
      return (
        <TransactionForm
          key={this.props.id}
          ref="transactionForm"
          allowanceTable={this.props.allowance.tables.find(
            (x) => x.id === this.props.directProductTableId,
          )}
          amount={this.props.amount}
          authority={this.props.authority}
          baseCurrencyId={this.props.baseCurrencyId}
          categories={this.props.categories}
          categoryName={this.props.categoryName}
          comment={this.props.comment}
          genericFields={this.props.genericFields}
          companions={this.props.companions}
          costAllocations={this.props.costAllocations}
          creditCategoryName={this.props.creditCategoryName}
          exchangePolicy={this.props.exchangePolicy}
          exchangeRate={this.props.exchangeRate}
          expenseAmountPerTaxCategories={
            this.props.expenseAmountPerTaxCategories
          }
          fields={this.props.fields}
          formValues={this.props.formValues}
          fromPreTransaction={this.props.fromPreTransaction}
          id={this.props.id}
          inModal={false}
          isCorporate={this.props.isCorporate}
          isElectronicReceiptImage={this.props.isElectronicReceiptImage}
          isMatchedOriginalReceipt={!isNil(this.props.matchedOriginalReceipt)}
          isNew={this.props.isNew}
          isReceiptMatched={!!this.props.receiptExpenseMatching}
          onRotateImage={this.props.onRotateImage?.bind(null, this.props.id)}
          onDeleteImage={this.props.onDeleteImage}
          onSelectCompanionsCategory={this.props.onSelectCompanionsCategory}
          onSubmit={this.onSubmit}
          onUnread={this.onUnread.bind(this)}
          originalAmount={this.props.originalAmount}
          originalAmountCurrencyId={this.props.originalAmountCurrencyId}
          ownerId={this.props.ownerId}
          preReportDepartment={this.props.preReportDepartment}
          preReportId={this.props.preReportId}
          preReportTitle={this.props.preReportTitle}
          project={this.props.project}
          localReceiptFile={this.props.localReceiptFile}
          receiptImages={this.props.receiptImages}
          reportId={this.props.reportId}
          reportTitle={this.props.reportTitle}
          route={this.props.route}
          shopName={this.props.shopName}
          shouldSelectSelfAsCompanion={this.props.shouldSelectSelfAsCompanion}
          status={this.props.status}
          suggestions={this.props.suggestions}
          taxCategories={this.props.taxCategories}
          taxCategoryName={this.props.taxCategoryName}
          transactedAt={this.props.transactedAt}
          transitPayee={this.props.transitPayee}
          isTaxAmountShow={this.props.isTaxAmountShow}
        />
      );
    }

    return this.props.isNew ? null : (
      <Transaction
        key={this.props.id}
        allowanceTable={this.props.allowance.tables.find(
          (x) => x.id === this.props.directProductTableId,
        )}
        amount={this.props.amount}
        authority={this.props.authority}
        category={this.props.category}
        categoryName={this.props.categoryName}
        comment={this.props.comment}
        companions={this.props.companions}
        costAllocations={this.props.costAllocations}
        creditCategoryName={this.props.creditCategoryName}
        currencyId={this.props.originalAmountCurrencyId}
        defaultCurrencyId={this.props.defaultCurrencyId}
        editable={editPerformable}
        exchangeRate={this.props.exchangeRate}
        expenseAmountPerTaxCategories={this.props.expenseAmountPerTaxCategories}
        fields={this.props.fields}
        formValues={this.props.formValues}
        groupName={this.props.groupName}
        id={this.props.id}
        inModal={false}
        isCorporate={this.props.isCorporate}
        isElectronicReceiptImage={this.props.isElectronicReceiptImage}
        onRotateImage={this.props.onRotateImage?.bind(null, this.props.id)}
        originalAmount={this.props.originalAmount}
        ownerId={this.props.ownerId}
        preReportId={this.props.preReportId}
        preReportSequenceNum={this.props.preReportSequenceNum}
        preReportTitle={this.props.preReportTitle}
        project={this.props.project}
        localReceiptFile={this.props.localReceiptFile}
        receiptImages={this.props.receiptImages}
        reportId={this.props.reportId}
        reportSequenceNum={this.props.reportSequenceNum}
        reportTitle={this.props.reportTitle}
        route={this.props.route}
        shopName={this.props.shopName}
        status={this.props.status}
        taxCategoryName={this.props.taxCategoryName}
        transactedAt={this.props.transactedAt}
        transitPayee={this.props.transitPayee}
        isTaxAmountShow={this.props.isTaxAmountShow}
      />
    );
  }

  renderRightDrawerFooter() {
    const { editable, permissions, ids, isDeleted } = this.props;
    const editPerformable =
      this.isRequester && editable && canUpdateAll(permissions);

    // 削除済み経費閲覧時
    if (isDeleted) {
      return (
        <RightDrawerFooter>
          {this.renderExpenseHistoryModalButton()}
        </RightDrawerFooter>
      );
    }

    // 編集不可の経費閲覧時
    if (!editPerformable) {
      // ワーカー入力中
      if (this.isRequester && canDelete(permissions)) {
        return (
          <RightDrawerFooter>{this.renderDeleteButton()}</RightDrawerFooter>
        );
      }
      return (
        <RightDrawerFooter>
          {this.renderExpenseHistoryModalButton()}
        </RightDrawerFooter>
      );
    }

    // 複数経費同時編集時
    if (ids.length >= 2) {
      return <RightDrawerFooter>{this.renderSubmitButton()}</RightDrawerFooter>;
    }

    // 経費編集時
    return (
      <RightDrawerFooter>
        {this.renderSubmitButton()}
        {this.renderOtherMenusDropdown()}
      </RightDrawerFooter>
    );
  }

  renderOtherMenusDropdown() {
    const {
      mergeableAggregation,
      permissions,
      reportId,
      inputBy,
      preReportId,
      onDeleteClick,
      onDetachClick,
    } = this.props;
    const isSettlementReport = reportId && preReportId;

    return (
      <Dropdown
        id="drop-down"
        direction="up"
        align="right"
        toggleButton={
          <DropdownToggleButton
            bsRole="toggle"
            styleType="secondary"
            direction="up"
          >
            {i18next.t("transactions.index.otherActions")}
          </DropdownToggleButton>
        }
      >
        {this.props.authority === "approver" && (
          <MenuItem onClick={this.onUnread}>
            {i18next.t("commons.actions.markAsUnread")}
          </MenuItem>
        )}
        <MenuItem
          onClick={() =>
            this.setState({ ...this.state, showExpenseHistoryModal: true })
          }
        >
          {i18next.t("transactions.histories.titles.history")}
        </MenuItem>
        {canDetach(permissions) && (
          <MenuItem onClick={onDetachClick}>
            {i18next.t("reports.requests.detach")}
          </MenuItem>
        )}
        {!isNil(mergeableAggregation) && (
          <MenuItem onClick={this.onMerge}>
            {i18next.t("transactions.inputs.mergeWithAggregation")}
          </MenuItem>
        )}
        <MenuItem
          color="red"
          onClick={onDeleteClick}
          disabled={!canDelete(permissions) || isSettlementReport}
        >
          {i18next.t("commons.actions.delete")}
        </MenuItem>
        {isSplittableExpense(this.props) && (
          <SplitStartDropdownButton
            onSelect={(v) => this.onSelectStartSplitDropdown(v)}
          />
        )}
        {
          <WorkerInputCorrectionRequestMenu
            expenseId={this.props.id}
            ownerId={this.props.ownerId}
            dataInputCompletedAt={this.props.createdAt}
          />
        }
      </Dropdown>
    );
  }

  renderDeleteButton() {
    return (
      <Button styleType="link-danger" onClick={this.props.onDeleteClick}>
        {i18next.t("commons.actions.delete")}
      </Button>
    );
  }

  renderSubmitButton() {
    const { inProcess, isNew, isFetchingInvoicingOrganization } = this.props;
    return (
      <Button
        styleType="primary"
        onClick={isFetchingInvoicingOrganization ? undefined : this.onUpdate}
        disabled={inProcess}
      >
        {i18next.t(`commons.actions.${isNew ? "register" : "save"}`)}
      </Button>
    );
  }

  renderMarkAdUnreadButton() {
    return (
      <Button
        styleType="link-primary"
        onClick={this.onUnread}
        disabled={this.props.inProcess}
      >
        <i className="fa fa-fw fa-none"></i>
        {i18next.t("commons.actions.markAsUnread")}
        <i className="fa fa-fw fa-none"></i>
      </Button>
    );
  }

  renderPaperlessMatchingButtons() {
    const { isAdmin } = userPreferences;
    const {
      isElectronicReceiptImage,
      receiptImages,
      receiptExpenseMatching,
      status,
    } = this.props;
    const electronicReceiptImageInput =
      this.props.formValues.electronic_receipt_image_input;
    const foreside = get(receiptImages, "foreside[0]", null);
    const backside = get(receiptImages, "backside[0]", null);
    const originalReceipt = receiptExpenseMatching?.originalReceipt;
    const notWaitingForWorker = status !== "waiting_for_worker";

    return (
      <>
        <div className="">
          <MarkingAsMatchedButton
            disabled={electronicReceiptImageInput}
            marked={!!receiptExpenseMatching}
            onClick={this.onClickOpenMarkingAsMatchedModal}
            show={
              isAdmin &&
              notWaitingForWorker &&
              !originalReceipt &&
              !isElectronicReceiptImage &&
              (foreside || backside)
            }
          />
        </div>
        <ConfirmOriginalReceiptButton
          foreside={foreside}
          backside={backside}
          matchedOriginalReceipt={originalReceipt}
          onClickOpenCancelReceiptMatchingModalButton={
            this.onClickOpenCancelReceiptMatchingModalButton
          }
        />
      </>
    );
  }

  renderPlpButons() {
    const showPaperlessMatchingButtons =
      userPreferences.isPaperlessPlan && isNil(userPreferences.agent);

    if (!showPaperlessMatchingButtons || this.props.isMultipleEditing)
      return null;

    return (
      <>
        {/* 右ドロワーと一括編集モーダルで表示スタイル分ける */}
        <div
          style={{
            position: "fixed",
            bottom: "60px",
            height: "47px",
            background: "#fff",
            left: this.props.ids.length > 1 ? 0 : "auto",
            width: "100%",
            borderTop: "1px solid #e4e8e9",
            paddingTop: "2px",
            paddingBottom: this.props.ids.length > 1 ? "8px" : "0px",
            marginBottom: this.props.ids.length > 1 ? "0px" : "8px",
          }}
        >
          {this.renderPaperlessMatchingButtons()}
        </div>
        <div style={{ height: "47px", background: "#fff" }} />
      </>
    );
  }

  /** 経費編集履歴モーダルを開くボタン */
  renderExpenseHistoryModalButton() {
    return (
      <Button
        styleType="link-primary"
        onClick={() =>
          this.setState({ ...this.state, showExpenseHistoryModal: true })
        }
      >
        {i18next.t("transactions.histories.titles.history")}
      </Button>
    );
  }

  /** 編集・閲覧で開く右ドロワー */
  renderRightDrawer() {
    let title;
    if (this.state.transactedAt && this.state.shopName && this.state.amount) {
      title = (
        <div className="" style={{ display: "flex" }}>
          <span style={{ marginRight: "16px" }}>
            {Formatter.date(this.state.transactedAt, {
              withDayOfTheWeek: true,
            })}
          </span>
          <span
            style={{
              marginRight: "16px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {this.state.shopName}
          </span>
          <span>{Formatter.amount(this.state.amount)}</span>
        </div>
      );
    } else {
      // 手入力でない場合、未入力項目を表示できないため「経費詳細」
      title = i18next.t("transactions.titles.details");
    }

    return (
      <RightDrawer
        width={1000}
        show={this.props.show}
        onShow={this.handleShowModal}
        onClose={this.props.closeModal}
        preventCloseSelector=".tr"
        // 基本モーダル内のクリックでは閉じないが、新規申請モーダル内で表示した場合のみモーダル内クリックで閉じる
        closeSelector=".report-create-modal"
        isLoading={this.props.inProcess}
        overflowY="hidden"
        resizable
        maxPositiveOffset={0}
      >
        <RightDrawerHeader
          onClose={this.props.closeModal}
          title={title}
          showCloseButton={true}
          showNextPrevButton={true}
          onGoNext={this.props.onGoToNextExpense}
          onGoPrev={this.props.onGoToPreviousExpense}
        />

        {this.state.shown && this.renderBody()}

        {this.renderPlpButons()}

        <div style={{ position: "fixed", bottom: "48px", width: "640px" }}>
          <EmbeddedFlash ref={this.embeddedFlash} />
        </div>

        {this.renderRightDrawerFooter()}
      </RightDrawer>
    );
  }

  /** 新規作成と一括編集で開くモーダル */
  renderModal() {
    let title;
    if (this.props.isNew) {
      title = i18next.t("transactions.titles.add");
    } else {
      // 同時編集の場合
      title = `${i18next.t("transactions.titles.edit")} ${
        this.props.ids.length
      }件`;
    }

    return (
      <CreateModalView
        show={this.props.show}
        onClose={this.props.closeModal}
        onShow={this.handleShowModal}
        backdrop={!this.props.isNew}
        width={this.props.ids.length >= 2 ? "sm" : "xl"}
        isLoading={this.props.inProcess}
        overflowY={this.props.ids.length >= 2 ? "auto" : "hidden"}
      >
        <ModalHeader title={title} onClose={this.props.closeModal} />
        <ModalBody>
          {this.state.shown && this.renderBody()}

          {!this.props.isNew && this.renderPlpButons()}

          <div style={{ position: "fixed", bottom: "48px", width: "94%" }}>
            <EmbeddedFlash ref={this.embeddedFlash} />
          </div>
        </ModalBody>
        <ModalFooter>
          {this.renderSubmitButton()}
          <Button styleType="link-secondary" onClick={this.props.closeModal}>
            {i18next.t("commons.actions.cancel")}
          </Button>
        </ModalFooter>
      </CreateModalView>
    );
  }

  render() {
    return (
      <>
        {/* 新規作成または一括編集時はモーダル、その他は右ドロワーで開く */}
        {this.props.isNew || this.props.ids.length >= 2
          ? this.renderModal()
          : this.renderRightDrawer()}

        {/* TODO: モーダルのボタン類もリニューアル版に置き換える */}
        <SimpleModal
          show={this.props.isDeleteModalOpen}
          close={this.props.closeDeleteModal}
          title={i18next.t("commons.messages.confirmDelete")}
          buttons={[
            {
              content: i18next.t("commons.actions.cancel"),
              color: "default",
              onClick: this.props.closeDeleteModal,
            },
            {
              content: i18next.t("commons.actions.delete"),
              color: "danger",
              onClick: this.props.deleteTransaction.bind(
                this,
                this.onDestroySuccess,
                this.onDestroyError,
              ),
            },
          ]}
        />
        <SimpleModal
          show={this.props.isDetachModalOpen}
          title={i18next.t("transactions.messages.confirmDetach")}
          close={this.props.closeDetachModal}
          buttons={[
            {
              content: i18next.t("reports.requests.detach"),
              color: "danger",
              onClick: this.detachExpense,
            },
          ]}
        />
        <MarkingAsMatchedModalContainer
          expenseId={this.props.id}
          onSubmitSuccess={this.props.onUpdateSuccess}
          receiptExpenseMatching={this.props.receiptExpenseMatching}
        />
        <CancelReceiptMatchingModal
          cancelMatching={this.onClickCancelMatchingButton.bind(this)}
          closeCancelReceiptMatchingModal={
            this.props.closeCancelReceiptMatchingModal
          }
          closeConfirmCancelMatchingModal={
            this.props.closeConfirmCancelMatchingModal
          }
          isCancelReceiptMatchingModalOpen={
            this.props.isCancelReceiptMatchingModalOpen
          }
          isConfirmCancelMatchingModalOpen={
            this.props.isConfirmCancelMatchingModalOpen
          }
          openConfirmDetachModal={this.props.openConfirmDetachModal}
          originalReceipt={this.props.receiptExpenseMatching?.originalReceipt}
        />
        <ExpenseHistoryModal
          show={this.state.showExpenseHistoryModal}
          onClose={() =>
            this.setState({ ...this.state, showExpenseHistoryModal: false })
          }
          expenseId={this.props.id}
          expense={{
            id: this.props.id,
            amount: this.props.amount,
            transactedAt: this.props.transactedAt,
            shop: this.props.shop,
            route: this.props.route,
          }}
        />
        <SplitModal
          show={this.state.showSplitModal}
          separatorType={this.state.separatorType}
          close={this.handleSplitModalClose}
          onSuccess={this.props.onSplitSuccess}
          expense={{
            id: this.props.id,
            amount: this.props.formValues?.expense_input?.amount,
            category: {
              id: this.props.formValues?.category_input?.id,
              name: this.props.formValues?.category_input?.name,
            },
            project: {
              id: this.props.formValues?.project_input?.id,
              name: this.props.formValues?.project_input?.name,
            },
            receiptImages: this.props.receiptImages,
          }}
          isVisibleResolutionInformation={
            userPreferences.preference.visibleResolutionInformation
          }
        />
      </>
    );
  }
}

ExpenseSingleViewer.defaultProps = {
  inProcess: false,
};

ExpenseSingleViewer.propTypes = {
  ...transactionType,
  categories: PropTypes.arrayOf(PropTypes.shape),
  closeDeleteModal: PropTypes.func.isRequired,
  closeDetachModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  deleteTransaction: PropTypes.func.isRequired,
  detachTransaction: PropTypes.func.isRequired,
  formData: PropTypes.shape(transactionType),
  fromPreTransaction: PropTypes.bool.isRequired,
  inProcess: PropTypes.bool,
  isDeleteModalOpen: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  markAsUnread: PropTypes.func.isRequired,
  onCreateSuccess: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onDestroySuccess: PropTypes.func.isRequired,
  onDetachClick: PropTypes.func.isRequired,
  onDetachSuccess: PropTypes.func.isRequired,
  onGoToNextExpense: PropTypes.func,
  onGoToPreviousExpense: PropTypes.func,
  onRotateImage: PropTypes.func,
  onDeleteImage: PropTypes.func,
  onSplitSuccess: PropTypes.func,
  onUpdateSuccess: PropTypes.func.isRequired,
  ownerId: PropTypes.string,
  preReportDepartment: PropTypes.string,
  preReportId: PropTypes.string,
  preReportSequenceNum: PropTypes.string,
  preReportTitle: PropTypes.string,
  receiptExpenseMatching: PropTypes.shape(),
  reportId: PropTypes.string,
  reportSequenceNum: PropTypes.string,
  reportTitle: PropTypes.string,
  shouldSelectSelfAsCompanion: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  suggestions: PropTypes.shape({
    taxCategories: PropTypes.arrayOf(PropTypes.shape),
    categories: PropTypes.arrayOf(PropTypes.shape),
    superCategories: PropTypes.arrayOf(PropTypes.shape),
    groups: PropTypes.arrayOf(PropTypes.shape),
    reportTitles: PropTypes.arrayOf(PropTypes.string),
    preReports: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        department: PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
      }),
    ),
  }),
  taxCategories: PropTypes.arrayOf(PropTypes.shape),
};

function mapStateToProps(state, ownProps) {
  const { formData, formState, suggestions, allowance } = state;

  return {
    ...formData,
    allowance,
    categories: suggestions.categories.total,
    defaultCurrencyId: formState.defaultCurrencyId,
    fields: formState.fields,
    inProcess: formState.inProcess,
    isFetchingInvoicingOrganization: formState.isFetchingInvoicingOrganization,
    isCancelReceiptMatchingModalOpen:
      formState.toggle.cancelReceiptMatchingModal,
    isConfirmCancelMatchingModalOpen:
      formState.toggle.confirmCancelMatchingModal,
    isDeleteModalOpen: formState.toggle.deleteModal,
    isDetachModalOpen: formState.toggle.detachModal,
    isNew: isNil(formData.id) && formData.ids.length === 0,
    isMultipleEditing: !!formData.ids.length,
    ownerId: ownProps.ownerId,
    shouldSelectSelfAsCompanion: ownProps.shouldSelectSelfAsCompanion,
    taxCategories: suggestions.taxCategories.total,
    suggestions: {
      taxCategories: suggestions.taxCategories.current,
      categories: suggestions.categories.current,
      superCategories: suggestions.superCategories.current,
      groups: suggestions.groups.current,
      reportTitles: suggestions.reportTitles.current,
      preReports: suggestions.preReports,
      ...omit(suggestions, [
        "taxCategories",
        "categories",
        "groups",
        "reportTitles",
        "preReports",
        "currencies",
        "superCategories",
      ]),
    },
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    detachTransaction(onSuccess) {
      dispatch(actions.detachTransaction(onSuccess));
    },
    onDeleteClick() {
      dispatch(actions.openDeleteModal());
    },
    closeDeleteModal() {
      dispatch(actions.closeDeleteModal());
    },
    onDetachClick() {
      dispatch(actions.openDetachModal());
    },
    closeDetachModal() {
      dispatch(actions.closeDetachModal());
    },
    openCancelReceiptMatchingModal() {
      dispatch(actions.openCancelReceiptMatchingModal());
    },
    closeCancelReceiptMatchingModal() {
      dispatch(actions.closeCancelReceiptMatchingModal());
    },
    openConfirmDetachModal() {
      dispatch(actions.openConfirmDetachModal());
    },
    closeConfirmCancelMatchingModal() {
      dispatch(actions.closeConfirmCancelMatchingModal());
    },
    openMarkingAsMatchedModal() {
      dispatch(markingAsMatchedActionCreators.openModal());
    },
    cancelMatching(originalReceiptId) {
      return dispatch(actions.cancelMatching(originalReceiptId));
    },
    deleteTransaction(onSuccess, onError) {
      dispatch(actions.deleteTransaction(onSuccess, onError));
    },
    onRotateImage(expenseId, receiptFile, rotation) {
      if (ownProps.onRotateImage && "id" in receiptFile) {
        ownProps.onRotateImage(expenseId, receiptFile.id, rotation);
      }

      // TransactionTableとTransactionModalは、異なるStoreに接続しているため、別々のactionをdispatchする
      // タイムスタンプ利用事業所で、画像回転後にバージョンを切り替えた時に、回転情報を保持するための処理
      dispatch(actions.rotateReceiptFile(receiptFile, rotation));
    },
    onDeleteImage(receiptFile) {
      if (ownProps.onDeleteImage && "id" in receiptFile) {
        ownProps.onDeleteImage(receiptFile);
      }

      dispatch(actions.deleteReceiptFile(receiptFile));
    },
    markAsUnread(id) {
      dispatch(transactionActions.markAsUnread(id));
    },
    fetchRemoteData(props) {
      // 削除済みの手当表のデータも取得する
      const period = {
        from: get(props.defaultPeriod, "from"),
        to: get(props.defaultPeriod, "to"),
      };
      return dispatch(
        allowanceActions.resetDirectProductTables(true, props.id, period, null),
      ).catch(() => {
        /* エラー表示後なので何もしない */
      });
    },
    resetAllowanceInput(
      allowanceTable,
      calculationFormulaVariableInputs,
      defaultPeriod,
    ) {
      if (!isNil(allowanceTable)) {
        dispatch(
          allowanceActions.resetInput(
            allowanceTable,
            calculationFormulaVariableInputs,
            defaultPeriod,
          ),
        );
      }
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps, void 0, {
  forwardRef: true,
})(ExpenseSingleViewer);
