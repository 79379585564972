import { PersonalCategory } from "utilities/api/models";
import { CategoriesByParentId } from "./CategoriesByParentId";

/**
 * 経費科目の一覧を親科目IDをキーにしたオブジェクトに変換します。
 * 最上位の経費科目は"top"というキーでアクセスできます。
 * @param categories 経費科目の一覧
 * @returns 親科目IDをキーにした経費科目のオブジェクト
 */
export function buildCategoriesByParentId(
  categories: PersonalCategory[],
): CategoriesByParentId {
  return categories.reduce((acc, category) => {
    const key = category.parentId || "top";
    return {
      ...acc,
      [key]: (acc[key] || []).concat(category),
    };
  }, {});
}
