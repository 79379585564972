import { ApiResult, fetchAsyncAll } from "actions/AsyncAction";
import InfiniteSelector from "components/fields/InfiniteSelector";
import SearchBox from "components/SearchBox";
import SearchResult from "components/SearchResult";
import i18next from "i18n";
import escapeRegExp from "lodash/escapeRegExp";
import uniqBy from "lodash/uniqBy";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { defer, Observable } from "rxjs";
import styled from "styled-components";
import Api from "utilities/api";
import { SuperCategory } from "utilities/api/models/SuperCategory";
import { IndexRequest } from "utilities/api/requests/superCategories";
import { snakecaseKeys } from "utilities/Utils";
import { SuperCategoryChoice } from "./types/SuperCategoryChoice";

interface SearchResult {
  options: SuperCategoryChoice[];
  totalCount: null | number;
}

interface Props {
  isMultiSelectable: boolean;
  value: null | SuperCategoryChoice | SuperCategoryChoice[];
  onSelect: (
    superCategory: SuperCategoryChoice,
    isSelected: boolean,
    selection: SuperCategoryChoice,
  ) => void;
}

const SearchFormView = styled.div`
  .search-result {
    margin-top: 16px;
  }
  .infinite-selector {
    margin-top: 8px;
  }
}
`;

function isMatchedForSearchText(
  superCategory: SuperCategoryChoice,
  searchText: string,
): boolean {
  return new RegExp(searchText).test(superCategory.name);
}

function formatOption(option: SuperCategoryChoice): JSX.Element {
  return (
    <div className="list-selector-item-content">
      <div>{option.name}</div>
    </div>
  );
}

function buildOptions(
  superCategories: SuperCategory[],
  value: null | SuperCategoryChoice | SuperCategoryChoice[],
): SuperCategoryChoice[] {
  const choices = superCategories.map((s) => {
    return { id: s.id, name: s.name };
  });

  if (value instanceof Array) {
    return uniqBy([...choices, ...value], "id");
  }

  if (value === null) {
    return uniqBy([...choices], "id");
  }

  return uniqBy([...choices, value], "id");
}

/** 勘定科目選択用コンポーネント */
const SuperCategorySelector: FC<Props> = (props) => {
  const defaultSearchResult: SearchResult = {
    options: [],
    totalCount: null,
  };

  const [searchResult, setSearchResult] =
    useState<SearchResult>(defaultSearchResult);
  const [searchText, setSearchText] = useState("");
  const [allOptions, setAllOptions] = useState<SuperCategoryChoice[]>([]);

  const deferredSuperCategories = useMemo<
    Observable<ApiResult<SuperCategory>>
  >(() => {
    return defer(() => {
      return fetchAsyncAll<SuperCategory, IndexRequest>(
        (params) =>
          Api.kernels.superCategories
            .index(
              snakecaseKeys({
                ...params,
                enable: true,
              }),
            )
            .then((result) => {
              return {
                data: result.superCategories,
                count: result.superCategories.length,
              };
            }),
        {
          /* APIを投げる際の追加パラメータは特になし */
        },
        { limit: 3000 },
      );
    });
  }, []);

  useEffect(() => {
    const subscription = deferredSuperCategories.subscribe((result) => {
      const choices = buildOptions(result.data, props.value);

      setAllOptions(choices);
      setSearchResult({ options: [...choices], totalCount: choices.length });
    });

    return (): void => {
      subscription.unsubscribe();
    };
  }, [deferredSuperCategories, props.value]);

  const search = useCallback(
    (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _offset,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _limit,
    ): Promise<{
      data: SuperCategoryChoice[];
      count: number;
    }> => {
      // 全体数が少ないため、offset, limitは非対応
      const superCategories =
        searchText === ""
          ? allOptions
          : allOptions.filter((s) =>
              isMatchedForSearchText(s, escapeRegExp(searchText)),
            );
      return Promise.resolve({
        data: superCategories,
        count: superCategories.length,
      });
    },
    [allOptions, searchText],
  );

  const handleOptionsChange = useCallback(
    (superCategories: SuperCategoryChoice[], count: null | number): void => {
      setSearchResult({ options: superCategories, totalCount: count });
    },
    [],
  );

  return (
    <InfiniteSelector
      isMultiSelectable={props.isMultiSelectable}
      options={searchResult.options}
      selection={props.value}
      totalCount={searchResult.totalCount}
      rowHeight={42}
      formatOption={formatOption}
      fetchOptions={search}
      onSelect={props.onSelect}
      onOptionsChange={handleOptionsChange}
    >
      {(renderSelector, { onOptionsReset }): JSX.Element => {
        return (
          <SearchFormView>
            <SearchBox
              value={searchText}
              placeholder={i18next.t(
                "superCategories.actions.searchPlaceholder",
              )}
              onChange={setSearchText}
              onClick={onOptionsReset}
            />
            <SearchResult count={searchResult.totalCount}>
              {renderSelector()}
            </SearchResult>
          </SearchFormView>
        );
      }}
    </InfiniteSelector>
  );
};

export default SuperCategorySelector;
