import styled from "styled-components";
import { tokens } from "../../tokens";
import { ButtonProps } from "./Button.types";
import { buttonColors, buttonSizes } from "./ButtonVariants";

export const StyledButton = styled.button<ButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${tokens.spacing.normal.$value};

  height: ${({ size }): string => buttonSizes[size || "default"].height};
  min-width: 160px;
  border-radius: ${tokens.border.radius.full.$value};

  font-size: ${({ size }): string => buttonSizes[size || "default"].fontSize};

  background: ${({ variant }): string =>
    buttonColors[variant || "primary"].background};
  color: ${({ variant }): string => buttonColors[variant || "primary"].color};
  border: ${({ variant }): string =>
    variant === "secondary"
      ? `1px solid ${buttonColors.secondary.color}`
      : "transparent"};

  &:hover {
    opacity: 0.4;
  }

  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
`;
