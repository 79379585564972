import React from "react";
import styled from "styled-components";
import { tokens } from "../../tokens";

interface WrapperProps {
  isChecked: boolean;
}
const Wrapper = styled.div<WrapperProps>`
  position: relative;
  display: flex;
  align-items: center;
  width: 52px;
  height: 28px;
  border-radius: ${tokens.border.radius.full.$value};
  border: 1px solid
    ${(p): string =>
      p.isChecked
        ? tokens.colors.background.black.primary.$value
        : tokens.colors.background.black.muted.$value};
  background: ${(p): string =>
    p.isChecked
      ? tokens.colors.background.black.primary.$value
      : tokens.colors.background.white.primary.$value};
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;
interface CircleProps {
  isChecked: boolean;
}
const Circle = styled.div<CircleProps>`
  position: absolute;
  height: 24px;
  width: 24px;
  left: ${(p): string => (p.isChecked ? "calc(100% - 25px)" : "1px")};
  border-radius: ${tokens.border.radius.full.$value};
  background: ${(p): string =>
    p.isChecked
      ? tokens.colors.background.white.primary.$value
      : tokens.colors.background.black.muted.$value};
  transition: 0.2s;
`;

interface Props {
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
}

/**
 * チェックボックス
 */
export const CheckBox: React.FC<Props> = ({ isChecked, onChange }) => (
  <Wrapper isChecked={isChecked} onClick={(): void => onChange(!isChecked)}>
    <Circle isChecked={isChecked} />
  </Wrapper>
);
