import * as allowanceActions from "applications/allowances/actions/transaction";
import { TransactionState as AllowanceTransactionState } from "applications/allowances/reducers/TransactionState";
import { SearchBoxState as TransitSearchBoxState } from "applications/fare_transactions/reducers/SearchBoxState";
import * as transactionFormPolicy from "applications/transactions/utilities/transactionFormPolicy";
import ConfirmationModal from "components/ConfirmationModal";
import i18next from "i18n";
import _get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import transactionType from "types/transaction";
import {
  validateCategory,
  validateCostAllocation,
  validateFare,
  validateSuperCategory,
  validateTaxCategory,
} from "utilities/validator";
import * as fareTransactionActions from "../../fare_transactions/actions";
import * as actions from "../actions";
import * as AsyncActions from "../actions/AsyncActions";
import { EntryForms } from "../components/EntryForms";
import { OperatorEntryConfirmModal } from "../components/EntryForms/AmountPerTaxCategoryCollectionFormField/components/OperatorEntryConfirmModal";
import { isAssignedOperatorEntry } from "../components/EntryForms/AmountPerTaxCategoryCollectionFormField/specification";
import { FormDataState } from "../reducers/FormDataState";
import { FormStateState } from "../reducers/FormStateState";
import { TransactionFormDispatchProps } from "./TransactionFormDispatchProps";
import { TransactionFormOwnProps } from "./TransactionFormOwnProps";
import { TransactionFormStateProps } from "./TransactionFormStateProps";

/**
 * TransactionForm コンポーネントが参照する Redux の State
 */
interface State {
  readonly formState: FormStateState;
  readonly formData: FormDataState;
  readonly allowance: AllowanceTransactionState;
  readonly searchBox: TransitSearchBoxState;
}

type Props = TransactionFormStateProps &
  TransactionFormDispatchProps &
  TransactionFormOwnProps;

export class TransactionForm extends Component<Props> {
  static defaultProps: Partial<Props>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static propTypes: any;

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount(): void {
    const isNew = this.props.isNew;
    this.props.fetchRemoteData(
      this.props.transactedAt,
      this.props.isMultipleEditing,
      this.props.ownerId || userPreferences.id,
    );
    const exceptUnknownCurrency =
      isNew || this.props.originalAmountCurrencyId !== "XXX";
    this.props.setExceptUnknownCurrency(exceptUnknownCurrency);
    this.props.fetchWithholdingConstants();
    this.props.initIsEditingWithholdingCategory(
      this.props.formValues.category_input
        ? this.props.formValues.category_input.requiresWithholding
        : false,
    );
    if (this.props.reportId && this.props.reportTitle) {
      this.props.onAssignableReportSelect({
        id: this.props.reportId,
        type: "report",
        title: this.props.reportTitle,
      });
    } else if (this.props.preReportId && this.props.preReportTitle) {
      this.props.onAssignableReportSelect({
        id: this.props.preReportId,
        type: "pre_report",
        title: this.props.preReportTitle,
      });
    }

    const isAllowance = !isNil(this.props.allowanceTable);
    if (isNew && isAllowance) {
      // 日当を新規作成する際には、デフォルトで適格請求書として扱う
      this.props.onAsEligibleInvoiceChange(true);
    }
  }

  /**
   * @returns {boolean} submit が可能か否か
   */
  handleSubmit(e): boolean {
    e.preventDefault();
    // NOTE: EnterKey や ボタンの連打で重複して送信されるのを防ぐため、送信中は送信を受け付けない。
    if (this.props.inProcess) {
      return false;
    }
    if (!this.validateForm()) {
      return false;
    }

    const { formValues, isMultipleEditing, fields } = this.props;
    const companions = formValues.companion_input;
    const category = formValues.category_input;
    const originAndDestination =
      formValues.origin_and_destination_by_category_input;
    const visitByCategory = formValues.visit_by_category_input;
    const purposeByCategory = formValues.purpose_by_category_input;
    const assignedGenericFields = formValues.generic_fields_input;
    const genericFieldsFields = fields.filter(
      (f) => f.type === "generic_fields_input",
    );

    const emptyRequiredFields: string[] = []; // 「必須」マークがついているものの、現在空欄であるフィールドを格納する配列

    if (
      !isMultipleEditing &&
      category &&
      category.requiresCompanion &&
      companions.length === 0
    ) {
      emptyRequiredFields.push(i18next.t("transactions.properties.companion"));
    }

    if (
      !isMultipleEditing &&
      category &&
      category.requiresOriginAndDestination &&
      (!originAndDestination?.originByCategory ||
        !originAndDestination?.destinationByCategory) &&
      !formValues.route_input
    ) {
      emptyRequiredFields.push(
        i18next.t("transactions.properties.originAndDestinationByCategory"),
      );
    }

    if (
      !isMultipleEditing &&
      category &&
      category.requiresVisit &&
      !visitByCategory &&
      !formValues.route_input
    ) {
      emptyRequiredFields.push(
        i18next.t("transactions.properties.visitByCategory"),
      );
    }

    if (
      !isMultipleEditing &&
      category &&
      category.requiresPurpose &&
      !purposeByCategory
    ) {
      emptyRequiredFields.push(
        i18next.t("transactions.properties.purposeByCategory"),
      );
    }

    // 汎用マスタに「必須入力」が設定されているにも関わらず、選択肢が何も選択されていなければpush
    if (!isMultipleEditing && assignedGenericFields) {
      assignedGenericFields.forEach((field) => {
        if (field.items.length > 0) return;

        const dataSetId = field.dataSetId;
        const categoryId = category.id;
        const targetGenericFieldsField = genericFieldsFields.find(
          (f) => f.dataSetId === dataSetId,
        );
        const genericFieldMustBeSelected =
          targetGenericFieldsField.requiredConditions[0].value.some(
            (f) => f === categoryId,
          );

        if (genericFieldMustBeSelected) {
          emptyRequiredFields.push(targetGenericFieldsField.label);
        }
      });
    }

    if (emptyRequiredFields.length > 0) {
      this.props.openConfirmationModal(emptyRequiredFields);
      return false;
    }

    if (isMultipleEditing && category && category.requiresWithholding) {
      this.props.openWithholdingConfirmationModal();
      return false;
    }

    if (
      this.props.operatorEntry &&
      !isAssignedOperatorEntry(
        this.props.operatorEntry,
        formValues.amount_per_tax_category_input || [],
      )
    ) {
      this.props.openOperatorEntryConfirmModal();
      return false;
    }

    this.props.submitForm(this.onSubmit, false, isMultipleEditing);
    return true;
  }

  onSubmit(expenses, error, message): void {
    const options = {
      reuseInput: this.props.reuseInput,
      isNew: this.props.isNew,
      formId: this.props.formId,
      lockSubmitButton: this.props.lockSubmitButton,
    };

    if (!error && expenses) {
      // 領収書のアップロードまで成功している場合、領収書データをアップロード済みのものに差し替える
      if (!this.props.isNew && !this.props.isMultipleEditing && expenses[0]) {
        this.props.resetReceiptFiles(expenses[0].id);
      }
    }

    this.props.onSubmit(options, expenses, error, message);
  }

  validateForm(): boolean {
    const { formValues } = this.props;

    // @ts-expect-error jQuery Validation Engine は React に置き換えるため一時的に ts-ignore とする。
    return $(".transaction-form").validationEngine("validate", {
      customFunctions: {
        validateFare,
        validateTaxCategory: validateTaxCategory.bind(
          null,
          this.props.suggestions.taxCategories.map((it) => it.name),
        ),
        validateCategory: validateCategory.bind(
          null,
          this.props.suggestions.categories.map((it) => it.name),
        ),
        validateSuperCategory: validateSuperCategory.bind(
          null,
          this.props.suggestions.superCategories.map((it) => it.name),
        ),
        validateProject: () =>
          isNil(formValues.project_input) ? "必須項目です" : void 0,
        validateCostAllocation: validateCostAllocation.bind(
          null,
          this.props.suggestions.groups.map((it) => it.name),
        ),
        validateSelect: (field) => {
          const foundClassName = Array.prototype.find.call(
            field[0].classList,
            (x) => x.startsWith("select-"),
          );

          if (!foundClassName) {
            return void 0;
          }

          return isNil(formValues[foundClassName.replace("select-", "")])
            ? "必須項目です"
            : void 0;
        },
        validateCustomValue: (field) => {
          const foundClassName = Array.prototype.find.call(
            field[0].classList,
            (x) => x.startsWith("custom-"),
          );

          if (!foundClassName) {
            return void 0;
          }

          return isNil(formValues[foundClassName.replace("custom-", "")])
            ? "必須項目です"
            : void 0;
        },
      },
      promptPosition: "bottomRight:-100",
    });
  }

  renderButton(): JSX.Element | null {
    const { isNew, reuseInput } = this.props;

    if (!isNew) return null;

    return (
      <div className="form-group">
        <div style={{ paddingTop: "16px", userSelect: "none" }}>
          <label className="txt-pointer pull-right">
            <input
              type="checkbox"
              checked={reuseInput}
              onChange={this.props.onReuseCheckChange}
              style={{ marginRight: "8px" }}
            />
            {i18next.t("transactions.inputs.reuse")}
          </label>
        </div>
      </div>
    );
  }

  render(): JSX.Element | null {
    const { receiptImages, isMultipleEditing } = this.props;

    return (
      <form
        role="form"
        className="transaction-form"
        onSubmit={this.handleSubmit}
      >
        {/* フォーカスが当たった状態でEnterKeyでhandleSubmitできるようinput(submit)設置 */}
        <input type="submit" className="hidden" />
        {transactionFormPolicy.hasFailedReceiptImage(receiptImages) ? (
          <div>
            <p className="bg-danger" style={{ padding: "8px" }}>
              {i18next.t("transactions.errors.failedToUploadImage")}
            </p>
          </div>
        ) : null}

        {transactionFormPolicy.hasFailedToSignReceiptImage(receiptImages) ? (
          <div>
            <p className="bg-danger" style={{ padding: "8px" }}>
              {i18next.t("transactions.errors.failedToSignTimeStamp")}
            </p>
          </div>
        ) : null}

        <EntryForms
          {...this.props}
          isNew={this.props.isNew}
          fields={this.props.fields}
          formValues={this.props.formValues}
          allowanceTable={this.props.allowanceTable}
          comment={this.props.comment}
          onCommentChange={this.props.onCommentChange}
          category={this.props.category}
          nestedCategoryNames={this.props.nestedCategoryNames}
          onCategorySuggestionsUpdateRequested={
            this.props.onCategorySuggestionsUpdateRequested
          }
          onCategorySuggestionSelected={this.props.onCategorySuggestionSelected}
          clearCategory={this.props.clearCategory}
          onCategoryChange={this.props.onCategoryChange}
          suggestions={this.props.suggestions}
        />

        {this.renderButton()}
        <ConfirmationModal
          title={i18next.t(
            "transactions.confirmationTitles.confirmWithholding",
          )}
          show={this.props.isWithholdingConfirmationModalOpen}
          inProcess={this.props.inProcess}
          close={this.props.closeWithholdingConfirmationModal}
          buttonText={i18next.t("commons.actions.yes")}
          denialButtonText={i18next.t("commons.actions.no")}
          onConfirm={this.props.submitForm.bind(
            null,
            this.onSubmit,
            true,
            isMultipleEditing,
          )}
          onDenialConfirm={this.props.submitForm.bind(
            null,
            this.onSubmit,
            false,
            isMultipleEditing,
          )}
          content={i18next.t("transactions.messages.confirmWithholding")}
        />
        <ConfirmationModal
          title={i18next.t(
            "transactions.confirmationTitles.confirmEmptyRequiredFields",
          )}
          show={this.props.isConfirmationModalOpen}
          inProcess={this.props.inProcess}
          close={this.props.closeConfirmationModal}
          buttonText={"OK"}
          onConfirm={this.props.submitForm.bind(null, this.onSubmit)}
          content={i18next.t(
            "transactions.messages.confirmEmptyRequiredfields",
            { emptyRequiredfields: this.props.emptyRequiredFields.join(",") },
          )}
        />
        {this.props.operatorEntry && (
          <OperatorEntryConfirmModal
            operatorEntry={this.props.operatorEntry}
            show={this.props.showOperatorEntryConfirmModal}
            onClose={this.props.closeOperatorEntryConfirmModal}
            onAccept={this.props.submitForm.bind(null, this.onSubmit)}
          />
        )}
      </form>
    );
  }
}

TransactionForm.defaultProps = {
  inModal: false,
  onSubmit(): void {},
  isEditingWithholdingCategory: true,
  withholdingConstants: {},
  inProcess: false,
  isDatePickerRendered: false,
  isMultiDateChecked: false,
  isConfirmationModalOpen: false,
  emptyRequiredFields: [],
  isWithholdingConfirmationModalOpen: false,
  reuseInput: false,
  onDeleteImage(): void {},
  shouldSelectSelfAsCompanion: true,
  isReceiptMatched: false,
  operatorEntry: null,
  showOperatorEntryConfirmModal: false,
  // 経費科目に関する初期値
  nestedCategories: [],
  nestedCategoryNames: [],
};

TransactionForm.propTypes = {
  isNew: PropTypes.bool.isRequired,
  inProcess: PropTypes.bool.isRequired,
  isDatePickerRendered: PropTypes.bool.isRequired,
  isMultiDateChecked: PropTypes.bool.isRequired,
  shouldSelectSelfAsCompanion: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isElectronicReceiptImage: PropTypes.bool,
  isConfirmationModalOpen: PropTypes.bool.isRequired,
  emptyRequiredFields: PropTypes.array.isRequired,
  isWithholdingConfirmationModalOpen: PropTypes.bool.isRequired,
  inModal: PropTypes.bool.isRequired,
  formId: PropTypes.string, // string | undefined
  fields: PropTypes.array.isRequired,
  formValues: PropTypes.object.isRequired,
  taxCategory: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  reuseInput: PropTypes.bool.isRequired,
  ...transactionType,
  suggestions: PropTypes.shape({
    taxCategories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        taxRate: PropTypes.number,
        groupId: PropTypes.string,
      }),
    ),
    superCategories: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ),
    reportTitles: PropTypes.arrayOf(PropTypes.string),
    preReports: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        department: PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
      }),
    ),
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ),
    // 経費科目の選択肢データの型定義
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        requiresCompanion: PropTypes.bool.isRequired,
      }),
    ),
  }),
  companions: PropTypes.array,
  mergeableAggregation: PropTypes.shape({
    name: PropTypes.string,
    categoryName: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  mergeTransaction: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  reloadCurrencies: PropTypes.func.isRequired,
  setAmount: PropTypes.func.isRequired,
  setOriginalAmount: PropTypes.func.isRequired,
  setCurrency: PropTypes.func.isRequired,
  setMetadata: PropTypes.func.isRequired,
  onShopNameChange: PropTypes.func.isRequired,
  onOriginByCategoryChange: PropTypes.func.isRequired,
  onDestinationByCategoryChange: PropTypes.func.isRequired,
  onVisitByCategoryChange: PropTypes.func.isRequired,
  onPurposeByCategoryChange: PropTypes.func.isRequired,
  onAsEligibleInvoiceChange: PropTypes.func.isRequired,
  onRegistratedNumberChange: PropTypes.func.isRequired,
  onRegistratedNumberVerify: PropTypes.func.isRequired,
  onPaidAddressChange: PropTypes.func.isRequired,
  onCheckCorporate: PropTypes.func.isRequired,
  onCheckElectronicReceiptImage: PropTypes.func.isRequired,
  onCommentChange: PropTypes.func.isRequired,
  onWithholdingChange: PropTypes.func.isRequired,
  onAddressChange: PropTypes.func.isRequired,
  onFullNameChange: PropTypes.func.isRequired,
  onPreReportChange: PropTypes.func.isRequired,
  onReuseCheckChange: PropTypes.func.isRequired,
  onTaxCategoryChange: PropTypes.func.isRequired,
  onTaxCategorySuggestionSelected: PropTypes.func.isRequired,
  onTaxCategorySuggestionsUpdateRequested: PropTypes.func.isRequired,
  clearTaxCategory: PropTypes.func.isRequired,
  onCreditCategoryChange: PropTypes.func.isRequired,
  onCreditCategorySuggestionSelected: PropTypes.func.isRequired,
  onCreditCategorySuggestionsUpdateRequested: PropTypes.func.isRequired,
  clearCreditCategory: PropTypes.func.isRequired,
  onReportTitleChange: PropTypes.func.isRequired,
  onReportTitleSuggestionSelected: PropTypes.func.isRequired,
  onReportTitleSuggestionsUpdateRequested: PropTypes.func.isRequired,
  clearReportTitle: PropTypes.func.isRequired,
  onAssignableReportSelect: PropTypes.func.isRequired,
  onGenericFieldsSelect: PropTypes.func.isRequired,
  onCompanionsSelect: PropTypes.func.isRequired,
  onProjectSelect: PropTypes.func.isRequired,
  onImageSelect: PropTypes.func.isRequired,
  onDeleteImage: PropTypes.func.isRequired,
  onRotateImage: PropTypes.func,
  setDatePickerStatus: PropTypes.func.isRequired,
  switchMultiDate: PropTypes.func.isRequired,
  openConfirmationModal: PropTypes.func.isRequired,
  closeConfirmationModal: PropTypes.func.isRequired,
  openWithholdingConfirmationModal: PropTypes.func.isRequired,
  closeWithholdingConfirmationModal: PropTypes.func.isRequired,
  costAllocations: PropTypes.arrayOf(
    PropTypes.shape({
      payerId: PropTypes.string,
      payerType: PropTypes.string,
      numerator: PropTypes.number,
      denominator: PropTypes.number,
    }),
  ),
  onDepartmentChanged: PropTypes.func.isRequired,
  reportId: PropTypes.string,
  reportTitle: PropTypes.string,
  preReportId: PropTypes.string,
  preReportTitle: PropTypes.string,
  preReportDepartment: PropTypes.string,
  isReceiptMatched: PropTypes.bool,
  transitPayee: PropTypes.string,
  operatorEntry: PropTypes.shape({
    total: PropTypes.number,
    subtotals: PropTypes.arrayOf(
      PropTypes.shape({
        taxRate: PropTypes.number,
        amount: PropTypes.number,
        taxAmount: PropTypes.number,
      }),
    ),
  }),
  showOperatorEntryConfirmModal: PropTypes.bool,
  // 経費科目に関するデータの型定義
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
    requiresCompanion: PropTypes.bool.isRequired,
  }),
  nestedCategories: PropTypes.array.isRequired,
  nestedCategoryNames: PropTypes.array.isRequired,
  // 経費科目に関するイベントハンドラの型定義
  onCategorySuggestionsUpdateRequested: PropTypes.func.isRequired,
  onCategorySuggestionSelected: PropTypes.func.isRequired,
  onCategoryChange: PropTypes.func.isRequired,
  clearCategory: PropTypes.func.isRequired,
};

function mapStateToProps(
  state: State,
  ownProps: TransactionFormOwnProps,
): TransactionFormStateProps {
  const { formState, formData, allowance, searchBox } = state;

  return {
    withholdingConstants: formState.withholdingConstants,
    isEditingWithholdingCategory: formState.isEditingWithholdingCategory,
    inProcess: formState.inProcess,
    isDatePickerRendered: formState.isDatePickerRendered,
    isConfirmationModalOpen: formState.toggle.confirmationModal,
    isWithholdingConfirmationModalOpen:
      formState.toggle.withholdingConfirmationModal,
    isMultiDateChecked: formState.isMultiDateChecked,
    shouldSelectSelfAsCompanion: ownProps.shouldSelectSelfAsCompanion,
    isEditable: formData.editable,
    isMultipleEditing: !!formData.ids.length,
    mergeableAggregation: formData.mergeableAggregation,
    taxCategory: formData.taxCategory,
    category: formData.category,
    nestedCategories: formData.nestedCategories,
    nestedCategoryNames: formData.nestedCategoryNames,
    currencyId: formData.originalAmountCurrencyId,
    reuseInput: formState.reuseInput,
    validations: formData.validations,
    defaultCurrencyId: formState.defaultCurrencyId,
    allowanceTable: allowance.tables.find(
      (table) => table.id === formData.directProductTableId,
    ),
    emptyRequiredFields: formState.emptyRequiredFields,
    fields:
      isNil(ownProps.fields) || isEmpty(ownProps.fields)
        ? formState.fields
        : ownProps.fields,
    formValues: formData.formValues,
    formId: formData.formId,
    transportCalculatedAmount: searchBox.amount,
    destination: searchBox.inputs.destination,
    operatorEntry: formData.operatorEntry,
    showOperatorEntryConfirmModal: formState.showOperatorEntryConfirmModal,
  };
}

function mapDispatchToProps(
  dispatch,
  ownProps: TransactionFormOwnProps,
): TransactionFormDispatchProps {
  return {
    submitForm(
      callback,
      isAutoWithholding = false,
      isMultipleEdit = false,
    ): void {
      dispatch(
        actions.submitForm(
          ownProps.isNew,
          ownProps.authority,
          ownProps.ownerId,
          isAutoWithholding,
          isMultipleEdit,
          callback,
          ownProps.lockSubmitButton,
        ),
      );
    },
    mergeTransaction(withAggregation, onSuccess, onError): void {
      dispatch(actions.mergeTransaction(withAggregation, onSuccess, onError));
    },
    onDateChange(dateStr): void {
      dispatch(actions.setTransactionDate(dateStr));
    },
    onTransitPayeeChange(transitPayee): void {
      dispatch(actions.setTransitPayee(transitPayee));
    },
    reloadCurrencies(currencyId, dateStr): void {
      dispatch(actions.reloadCurrencies(currencyId, dateStr));
    },
    setOriginalAmount(amount): void {
      dispatch(actions.setOriginalAmount(amount));
    },
    setAmount(amount): void {
      dispatch(actions.setAmount(amount));
    },
    setCurrency(currency): void {
      dispatch(actions.setCurrency(currency));
    },
    onChangeAmountPerTaxCategories(amountPerTaxCategories): void {
      dispatch(actions.setAmountPerTaxCategories(amountPerTaxCategories));
    },
    onDestinationChange(value): void {
      dispatch(actions.setDestination(value));
      dispatch(fareTransactionActions.setDestination(value));
    },
    onDestinationSelect(suggestion): void {
      dispatch(fareTransactionActions.selectDestinationSuggestion(suggestion));
    },
    onShopNameChange(shopName): void {
      dispatch(actions.setShopName(shopName));
    },
    onOriginByCategoryChange(e, destinationByCategory): void {
      dispatch(actions.setOriginByCategory(e, destinationByCategory));
    },
    onDestinationByCategoryChange(e, originByCategory): void {
      dispatch(actions.setDestinationByCategory(e, originByCategory));
    },
    onVisitByCategoryChange(visitByCategory): void {
      dispatch(actions.setVisitByCategory(visitByCategory));
    },
    onPurposeByCategoryChange(purposeByCategory): void {
      dispatch(actions.setPurposeByCategory(purposeByCategory));
    },
    onAsEligibleInvoiceChange(asEligibleInvoice): void {
      dispatch(
        actions.changeAsEligibleInvoiceWithTaxCategories(asEligibleInvoice),
      );
    },
    onRegistratedNumberChange(registratedNumber): void {
      // invoicingOrganization に null もしくは InvoicingOrganization が入っている時(=APIから取得してきた結果が格納されている時)、初期値undefinedに戻す
      dispatch(actions.setInvoicingOrganization(undefined));

      dispatch(actions.setRegistratedNumber(registratedNumber));
    },
    onRegistratedNumberVerify(): void {
      dispatch(actions.fetchVerifyRegistratedNumber());
    },
    onPaidAddressChange(paidAddress): void {
      dispatch(actions.setPaidAddress(paidAddress));
    },
    onExchangeButtonClick(originByCategory, destinationByCategory): void {
      dispatch(
        actions.exchangeOriginAndDestination(
          originByCategory,
          destinationByCategory,
        ),
      );
    },
    onTaxCategoryChange(taxCategory): void {
      dispatch(actions.setTaxCategoryName(taxCategory));
    },
    onTaxCategorySuggestionSelected(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      e,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { suggestion, suggestionValue, method },
    ): void {
      dispatch(actions.setTaxCategoryName(suggestionValue));
    },
    onTaxCategorySuggestionsUpdateRequested({ value, reason }): void {
      dispatch(actions.requestTaxCategorySuggestionsUpdate({ value, reason }));
    },
    onCreditCategoryChange(category): void {
      dispatch(actions.setCreditCategoryName(category));
    },
    onCreditCategorySuggestionSelected(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      e,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { suggestion, suggestionValue, method },
    ): void {
      dispatch(actions.setCreditCategoryName(suggestionValue));
    },
    onCreditCategorySuggestionsUpdateRequested({ value, reason }): void {
      dispatch(
        actions.requestCreditCategorySuggestionsUpdate({ value, reason }),
      );
    },
    onChangeCostAllocationInput(group, index): void {
      dispatch(actions.updateCostAllocationsByInput(group, index));
    },
    onSelectCostAllocation(deptId, index): void {
      dispatch(actions.updateCostAllocationsById(deptId, index));
    },
    onPreReportChange(preReport): void {
      dispatch(actions.setPreReportWithDepartment(preReport));
    },
    onReportTitleChange(reportTitle): void {
      dispatch(actions.setReportTitle(reportTitle));
    },
    onAssignableReportSelect(assignableReport): void {
      const { id, type, title } = assignableReport;
      dispatch(actions.setAssignableReport({ id, type, title }));
    },
    onReportTitleSuggestionSelected(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      e,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { suggestion, suggestionValue, method },
    ): void {
      dispatch(actions.setReportTitle(suggestionValue));
    },
    onReportTitleSuggestionsUpdateRequested({ value, reason }): void {
      dispatch(actions.requestReportTitleSuggestionsUpdate({ value, reason }));
    },
    onProjectSelect(project): void {
      dispatch(actions.setProject(project));
    },
    onGenericFieldsSelect(genericFields): void {
      dispatch(actions.setGenericFields(genericFields));
    },
    onCompanionsSelect(companions): void {
      dispatch(actions.setCompanions(companions));
    },
    onCheckCorporate(isChecked): void {
      dispatch(actions.checkCorporate(isChecked));
    },
    onCheckElectronicReceiptImage(isChecked): void {
      dispatch(actions.checkElectronicReceiptImage(isChecked));
    },
    onCommentChange(comment): void {
      dispatch(actions.setComment(comment));
    },
    onWithholdingChange(withholding): void {
      dispatch(actions.setWithholding(withholding));
    },
    onWithholdingAutoChange(withholding): void {
      dispatch(actions.autoSetWithholding(withholding));
    },
    onAddressChange(address): void {
      dispatch(actions.setAddress(address));
    },
    onFullNameChange(name): void {
      dispatch(actions.setFullName(name));
    },
    onImageSelect(file, isBackside): void {
      dispatch(actions.setLocalReceiptFile(file, isBackside));
    },
    resetReceiptFiles(expenseId): void {
      dispatch(actions.resetReceiptFiles(expenseId));
    },
    clearTaxCategory(): void {
      dispatch(actions.setTaxCategoryName(""));
    },
    clearCreditCategory(): void {
      dispatch(actions.setCreditCategoryName(""));
    },
    clearReportTitle(): void {
      dispatch(actions.setReportTitle(""));
    },
    switchMultiDate(): void {
      dispatch(actions.switchMultiDate());
    },
    openConfirmationModal(emptyRequiredFields): void {
      dispatch(actions.onChangeEmptyRequiredFields(emptyRequiredFields));
      dispatch(actions.openConfirmationModal());
    },
    closeConfirmationModal(): void {
      dispatch(actions.resetEmptyRequiredFields());
      dispatch(actions.closeConfirmationModal());
    },
    openWithholdingConfirmationModal(): void {
      dispatch(actions.openWithholdingConfirmationModal());
    },
    closeWithholdingConfirmationModal(): void {
      dispatch(actions.closeWithholdingConfirmationModal());
    },
    onReuseCheckChange(): void {
      dispatch(actions.checkReuseInput());
    },
    setDatePickerStatus(show): void {
      dispatch(actions.setDatePickerStatus(show));
    },
    fetchRemoteData(dateStr, isMultipleEditing, ownerId): void {
      if (ownProps.id) {
        dispatch(actions.fetchOperatorEntry(ownProps.id));
      }
      dispatch(actions.fetchCategories(ownProps.isNew, true));
      if (ownProps.authority === "approver") {
        dispatch(actions.fetchSuperCategories());
      }
      dispatch(actions.fetchReportTitles());
      dispatch(actions.fetchPreReports());
      dispatch(AsyncActions.fetchDepartments());
      dispatch(AsyncActions.fetchUserDepartments(ownerId));
      if (!isMultipleEditing) {
        dispatch(
          actions.fetchCurrencies(
            dateStr ||
              new Date().toLocaleDateString("ja", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }),
          ),
        );
      }
    },
    setAllowanceInput(allowanceTable, key, value): void {
      dispatch(allowanceActions.setInputValue(allowanceTable, key, value));
    },
    setMetadata(id, value): void {
      dispatch(actions.setMetadata(id, value));
    },
    fetchWithholdingConstants(): void {
      dispatch(actions.fetchWithholdingConstants());
    },
    setExceptUnknownCurrency(exceptUnknownCurrency): void {
      dispatch(actions.setExceptUnknownCurrency(exceptUnknownCurrency));
    },
    onDepartmentChanged(option, isMultipleEditing): void {
      dispatch(
        actions.setDepartmentWithCostAllocations(option, isMultipleEditing),
      );
    },
    initShouldSelectSelfAsCompanion(fields, formValues, canRead): void {
      const showCompanions = fields
        .filter((f) => canRead(f))
        .find((x) => x.type === "companion_input");
      const companionInput = formValues.companion_input;
      const companionNum = _get(companionInput, "length", 0);

      // 参加者がすでに入力されているか、参加者の入力が必要なのに参加者が意図的に空にされている時は
      // 自分自身を参加者として選択する
      const shouldNotSelectSelfAsCompanion =
        companionNum > 0 || (showCompanions && companionNum === 0);
      dispatch(
        actions.setShouldSelectSelfAsCompanion(!shouldNotSelectSelfAsCompanion),
      );
    },
    initIsEditingWithholdingCategory(requiresWithholding): void {
      if (requiresWithholding) {
        dispatch(actions.setIsEditingWithholdingCategory(true));
      } else {
        dispatch(actions.setIsEditingWithholdingCategory(false));
      }
    },
    openOperatorEntryConfirmModal(): void {
      dispatch(actions.openOperatorEntryConfirmModal());
    },
    closeOperatorEntryConfirmModal(): void {
      dispatch(actions.closeOperatorEntryConfirmModal());
    },
    // 経費科目に関するイベントハンドラ
    onCategorySuggestionsUpdateRequested({ value, reason }, level): void {
      dispatch(
        actions.requestNestedCategorySuggestionsUpdate(
          { value, reason },
          level,
        ),
      );
    },
    onCategorySuggestionSelected(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      e,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { suggestion, suggestionValue, method },
      index,
    ): void {
      dispatch(actions.selectNestedCategory(suggestionValue, index));
    },
    onCategoryChange(
      categoryName,
      suggestions,
      index,
      asInvoiceProcess,
      isNew = false,
      isAllowance = false,
    ): void {
      const categories = suggestions.categories;
      let category = null;
      if (categoryName.length) {
        category = categories.find((c) => c.name === categoryName) || null;
      }
      if (_get(category, "selectable")) {
        const isCategoryNotRequiresReceivingInvoice = _get(
          category,
          "notRequiresReceivingInvoice",
        );

        let isAsEliglbleInvoiceChangePolicy = "";
        if (isAllowance) {
          // 日当の時
          if (isNew) {
            isAsEliglbleInvoiceChangePolicy = "change_to_true";
          } else {
            isAsEliglbleInvoiceChangePolicy = "keep_current"; // 日当の編集時 現在の値を保持 (適格請求書確認(登録時)リリース以降に作成された日当に関してはtrueで作成され、それ以前の経費に関しては基本的にfalseとなる)
          }
        } else {
          // 日当「以外」の時
          // eslint-disable-next-line no-lonely-if
          if (asInvoiceProcess) {
            // 適格請求書発行事業者が、適格な事業所として扱われる時(= 処理区分が 01 もしくは 02 )
            isAsEliglbleInvoiceChangePolicy = "change_to_true";
          } else if (!isAllowance && isCategoryNotRequiresReceivingInvoice) {
            isAsEliglbleInvoiceChangePolicy = "change_to_true";
          } else {
            isAsEliglbleInvoiceChangePolicy = "change_to_false";
          }
        }

        dispatch(
          actions.setCategoryName(
            categoryName,
            isAsEliglbleInvoiceChangePolicy,
          ),
        );
        dispatch(actions.setTaxCategoryName(""));
      }
      dispatch(actions.selectNestedCategory(categoryName, index));
      dispatch(actions.setNestedCategoryName(categoryName, index));
    },
    clearCategory(
      category,
      index,
      isLeafCategory,
      asInvoiceProcess,
      isNew = false,
      isAllowance = false,
    ): void {
      if (isLeafCategory) {
        let isAsEliglbleInvoiceChangePolicy = "";
        if (isAllowance) {
          // 日当の時
          if (isNew) {
            isAsEliglbleInvoiceChangePolicy = "change_to_true";
          } else {
            isAsEliglbleInvoiceChangePolicy = "keep_current"; // 日当の編集時 現在の値を保持 (適格請求書確認(登録時)リリース以降に作成された日当に関してはtrueで作成され、それ以前の経費に関しては基本的にfalseとなる)
          }
        } else {
          // 日当「以外」の時
          // eslint-disable-next-line no-lonely-if
          if (asInvoiceProcess) {
            // 適格請求書発行事業者が、適格な事業所として扱われる時(= 処理区分が 01 もしくは 02 )
            isAsEliglbleInvoiceChangePolicy = "change_to_true";
          } else {
            isAsEliglbleInvoiceChangePolicy = "change_to_false";
          }
        }

        dispatch(actions.setCategoryName("", isAsEliglbleInvoiceChangePolicy));
        dispatch(actions.setTaxCategoryName(""));
      }
      dispatch(actions.selectNestedCategory("", index));
      dispatch(actions.resetNestedCategorySuggestions(index + 1));
    },
  };
}

export default connect<
  TransactionFormStateProps,
  TransactionFormDispatchProps,
  TransactionFormOwnProps,
  State
>(mapStateToProps, mapDispatchToProps, void 0, {
  forwardRef: true,
})(TransactionForm);
