import { ReorganizationsChangeConfirmModal } from "applications/kernels/organizations/reorganizations/compornents/ConfirmModal";
import i18next from "i18n";
import React from "react";

interface Props {
  isVisible: boolean;
  handleClickApply: () => void;
  handleClose: () => void;
}

/**
 * 組織マスタ変更予約: 即時反映確認モーダル
 */
export const ReorganizationsChangeImmediateApplyConfirmModal: React.FC<
  Props
> = (props): JSX.Element => {
  return (
    <ReorganizationsChangeConfirmModal
      isVisible={props.isVisible}
      applyText={i18next.t(
        "reorganizations.changeDetail.actions.immediateApplyButton",
      )}
      title={i18next.t(
        "reorganizations.changeDetail.modal.immediateApplyConfirmationModal.title",
      )}
      disabled={false}
      handleClose={props.handleClose}
      handleClickApply={props.handleClickApply}
    >
      {i18next.t(
        "reorganizations.changeDetail.modal.immediateApplyConfirmationModal.text",
      )}
    </ReorganizationsChangeConfirmModal>
  );
};
