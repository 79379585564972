import { PersonalCategory } from "utilities/api/models";
import { CategoriesByParentId } from "./CategoriesByParentId";
import { CategorySuggestion } from "./CategorySuggestion";

/**
 * 親科目から現在の経費科目までの経費科目の候補を一覧で取得します。
 * このメソッドは再帰的に呼び出されます。
 * @param category 現在の経費科目
 * @param categories すべての経費科目の一覧
 * @param categoriesByParentId 親科目IDをキーにした経費科目のオブジェクト
 * @param nestedSuggestions 計算中の候補の一覧(初期値は空配列)
 * @returns 親科目から現在の経費科目までの経費科目の候補一覧
 */
function getSuggestionRecursive(
  category: PersonalCategory,
  categories: PersonalCategory[],
  categoriesByParentId: CategoriesByParentId,
  nestedSuggestions: CategorySuggestion[] = [],
): CategorySuggestion[] {
  const parentCategory = categories.find((c) => c.id === category.parentId);
  const siblings = categoriesByParentId[parentCategory?.id || "top"];
  const nextNestedSuggestions = nestedSuggestions.concat({
    total: siblings,
    current: siblings,
  });

  if (category.parentId && parentCategory) {
    return getSuggestionRecursive(
      parentCategory,
      categories,
      categoriesByParentId,
      nextNestedSuggestions,
    );
  }
  return nextNestedSuggestions.reverse();
}

/**
 * 親科目から現在の経費科目までの経費科目の候補を一覧で取得します。
 * 現在の経費科目が親科目の場合、その子どもの経費科目も候補に含めます。
 * @param category 現在の経費科目
 * @param categories すべての経費科目の一覧
 * @param categoriesByParentId 親科目IDをキーにした経費科目のオブジェクト
 * @returns 親科目から現在の経費科目までの経費科目の候補一覧
 */
export function getNestedSuggestions(
  category: PersonalCategory,
  categories: PersonalCategory[],
  categoriesByParentId: CategoriesByParentId,
): CategorySuggestion[] {
  let nestedCategoriesSuggestions = getSuggestionRecursive(
    category,
    categories,
    categoriesByParentId,
  );

  // selectable=falseの経費科目が入力されている経費を編集するとき
  // 入力されていた経費科目が事業所設定で親科目に変更された場合に発生する
  if (!category.selectable) {
    const children = categories.filter((c) => c.parentId === category.id);
    nestedCategoriesSuggestions = nestedCategoriesSuggestions.concat({
      total: children,
      current: children,
    });
  }
  return nestedCategoriesSuggestions;
}
