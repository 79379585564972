export const colors = {
  $schema: "https://tr.designtokens.org/format/",
  color: {
    brand: {
      primary: { $value: "#008D97" }, // key/primary
      secondary: { $value: "#1A7EDC" }, // key/secondary
      subPrimary: { $value: "#00CC99" }, // key/sub_primary_1
      tertiary: { $value: "#2EA8A9" }, // key/sub_primary_2
    },
    status: {
      info: { $value: "#1A7EDC" }, // key/secondary
      success: { $value: "#00CC99" }, // key/sub_primary_1
      attention: { $value: "#F98938" }, // semantic/attention
      warning: { $value: "#F98938" }, // accent
      error: { $value: "#EF5350" }, // semantic/error
    },
    text: {
      white: {
        primary: { $value: "#FFFFFF" }, // text/white
      },
      black: {
        primary: { $value: "#191414" }, // text/black_1
        secondary: { $value: "#4C4C4C" }, // text/black_2
        muted: { $value: "#AEAEAE" }, // text/black_3
      },
    },
    background: {
      white: {
        primary: { $value: "#FFFFFF" }, // background/bg_white
      },
      black: {
        primary: { $value: "#333333" }, // Grey/Black
        muted: { $value: "#AEAEAE" }, // text/black_3
      },
      lightGray: {
        primary: { $value: "#F4F4F4" }, // background/bg_1
        secondary: { $value: "#F9F9F9" }, // background/bg_2
      },
    },
  },
};
