import React from "react";
import styled from "styled-components";
import { BreadcrumbItem } from "./interface";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const Item = styled.a`
  color: #004acc;

  &:hover {
    cursor: pointer;
  }
`;

interface Props {
  items: BreadcrumbItem[];
}

/**
 * パンくずリスト
 */
export const Breadcrumbs: React.FC<Props> = (props) => {
  return (
    <Wrapper>
      {props.items.map((item, index) => (
        <React.Fragment key={`breadcrumbs-item-${index}`}>
          {index !== 0 && <span className="fa fa-right fa-angle-right" />}
          {item.url !== "" && <Item href={item.url}>{item.text}</Item>}
          {item.url === "" && <span>{item.text}</span>}
        </React.Fragment>
      ))}
    </Wrapper>
  );
};
