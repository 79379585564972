import { ReorganizationsChangeDeleteConfirmModal } from "applications/kernels/organizations/reorganizations/compornents/DeleteConfirmModal";
import i18next from "i18n";
import React from "react";

interface Props {
  title: string;
  isVisible: boolean;
  handleClickDeleteButton: () => void;
  handleClose: () => void;
}

/**
 * 組織マスタ変更予約: 取り消しの確認モーダル
 */
export const ReorganizationsChangeCancelConfirmModal: React.FC<Props> = (
  props,
): JSX.Element => {
  return (
    <ReorganizationsChangeDeleteConfirmModal
      isVisible={props.isVisible}
      title={props.title}
      deleteText={i18next.t(
        "reorganizations.changeDetail.actions.reverseButton",
      )}
      handleClickDelete={props.handleClickDeleteButton}
      handleClose={props.handleClose}
    />
  );
};
