/**
 * 経費詳細画面上から自動入力修正依頼のGoogleフォームを開くための関数
 */

import { InquiryFormsResponse } from "utilities/api_adi/responses/inquiryForms";

export function openWorkerInputCorrectionRequestForm(
  expenseId: string,
  ownerId: string,
  dataInputCompletedAt: string,
  inquiryForm: InquiryFormsResponse | undefined,
): void {
  /** 経費の自動入力修正依頼フォームの通常のURL */
  const baseFormURL = inquiryForm?.tkWorkerInputCorrectionRequestForm.url;

  /**
   * 事前入力されたGoogleFormのURLを生成するためのパラメーター
   * 自動の事前入力が必要な入力フォームに、値を対応させておく
   */
  const entryKeys = inquiryForm?.tkWorkerInputCorrectionRequestForm.entryKeys;
  const urlParams = new URLSearchParams({
    [`${entryKeys?.googleFormRootGroupId}`]: userPreferences.rootGroup.id, // rootGroupID
    [`${entryKeys?.googleFormRootGroupName}`]: userPreferences.rootGroup.name, // テナント名
    [`${entryKeys?.googleFormName}`]: userPreferences.name, // 氏名
    [`${entryKeys?.googleFormEmail}`]: userPreferences.email, // メールアドレス
    [`${entryKeys?.googleFormId}`]: expenseId, // 経費のuuid
    [`${entryKeys?.googleFormExpenseCreatedBy}`]: ownerId, // 作成者ID
    [`${entryKeys?.googleFormDataInputCompletedAt}`]: dataInputCompletedAt, // 自動入力完了日時
  });

  const prefilledFormURL = `${baseFormURL}?${urlParams.toString()}`;

  window.open(prefilledFormURL, "_blank", "noreferer");
}
