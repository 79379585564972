export const typography = {
  $schema: "https://tr.designtokens.org/format/",
  font: {
    headline: {
      h1: {
        fontWeight: { $value: 700 },
        fontSize: { $value: "24px" },
      },
      h2: {
        fontWeight: { $value: 700 },
        fontSize: { $value: "20px" },
      },
      h3: {
        fontWeight: { $value: 700 },
        fontSize: { $value: "16px" },
      },
      h4: {
        fontWeight: { $value: 700 },
        fontSize: { $value: "14px" },
      },
      h5: {
        fontWeight: { $value: 700 },
        fontSize: { $value: "12px" },
      },
    },
    body: {
      body1: {
        fontSize: { $value: "16px" },
      },
      body2: {
        fontSize: { $value: "14px" },
      },
      body3: {
        fontSize: { $value: "12px" },
      },
    },
  },
};
