import { tokens } from "../../tokens";

export const buttonSizes = {
  small: {
    height: "32px",
    fontSize: tokens.typography.headline.h3.fontSize.$value,
  },
  default: {
    height: "40px",
    fontSize: tokens.typography.headline.h3.fontSize.$value,
  },
  large: {
    height: "60px",
    fontSize: tokens.typography.headline.h4.fontSize.$value,
  },
};

export const buttonColors = {
  primary: {
    background: tokens.colors.brand.secondary.$value,
    color: tokens.colors.background.white.primary.$value,
  },
  secondary: {
    background: tokens.colors.background.white.primary.$value,
    color: tokens.colors.brand.secondary.$value,
  },
  linkText: {
    background: "transparent",
    color: tokens.colors.brand.secondary.$value,
  },
};
