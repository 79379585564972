import { useState } from "react";
import { mergeFetchingData } from "utilities/Utils";

export interface TableFetchResponse<Res> {
  rows: (Res | undefined)[];
  count: number;
  offset: number;
}

interface UseReturn<Res> {
  /** ローディング中か */
  isLoading: boolean;
  /** テーブルデータ一覧 */
  rows: (Res | undefined)[];
  /** データ取得 */
  fetchTable: (
    fetchTableRow: () => Promise<TableFetchResponse<Res>>,
    background: boolean,
    isCacheClear: boolean,
  ) => Promise<void>;
}

/**
 * テーブルデータ取得
 * undefinedで埋められた総数分のテーブルデータを返す
 */
export const useFetchTable = <Res>(): UseReturn<Res> => {
  const [rows, setRows] = useState<(Res | undefined)[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /**
   * データ取得
   * @param fetchTableRow データ取得関数
   * @param background ローディングするか
   * @param isCacheClear キャッシュクリアするか
   */
  const fetchTable = async (
    fetchTableRow: () => Promise<TableFetchResponse<Res>>,
    background: boolean,
    isCacheClear: boolean,
  ): Promise<void> => {
    if (!background) setIsLoading(true);

    try {
      const res = await fetchTableRow();
      // undefinedで埋められたテーブル総数の配列
      setRows((v) =>
        mergeFetchingData(
          isCacheClear ? [] : v,
          res.rows,
          res.count,
          res.offset,
        ),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    rows,
    fetchTable,
  };
};
